const {
  STANDARD_SHIPPING_PRICE,
  STANDARD_SOUTH_SHIPPING_PRICE,
  EXPRESS_SHIPPING_PRICE,
  EXPRESS_SOUTH_SHIPPING_PRICE,
  INTERNATIONAL_SHIPPING_PRICE,
} = require('../constants')

const ShippingCalc = function(order,customer){

    const regionFactor = {
      "0" : "north",
      "1" : "north",
      "2" : "north",
      "3" : "north",
      "4" : "north",
      "5" : "north",
      "6" : "south",
      "7" : "south",
      "8" : "south",
      "9" : "south",
    };
  
    const choiceFactor = {
      "standard" : {
        north : {
          price : STANDARD_SHIPPING_PRICE,
          code : "A104"
        },
        south : {
          price : STANDARD_SOUTH_SHIPPING_PRICE,
          code : "A104"
        }
      },
      "express" : {
        north : {
          price : EXPRESS_SHIPPING_PRICE,
          code : "A16A4"
        },
        south : {
          price : EXPRESS_SOUTH_SHIPPING_PRICE,
          code : "A117"
        }
      },
      "international": {
        price : INTERNATIONAL_SHIPPING_PRICE,
        code : "DHL"
      }
    };
  
    let region = regionFactor[customer.postcode[0]];
  
    let qty = 0;
    order.forEach(function(item){
      qty += item.qty;
    });

    let shippingoption = choiceFactor[customer.shipping][region];
    if (customer.shipping === 'international') {
      shippingoption = choiceFactor[customer.shipping]
    }  
    return {
      price : shippingoption.price,
      qtyFactor : qty,
      total : shippingoption.price,
      code : shippingoption.code
    };
  
  };
  
  module.exports = ShippingCalc;