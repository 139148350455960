import React from 'react';

const Accounting = ({orders}) => {
  const dateBrackets = {}

  orders.forEach(function(element){
    var date = element.createdAt.substring(0,7);


    if(dateBrackets.hasOwnProperty(date) === false){
      dateBrackets[date] = 0;
    }
  });

  orders.forEach(function(element){
    var date = element.createdAt.substring(0,7);

    dateBrackets[date] += Number(element.total);
  });

  var revenuesArray = [];

  for (var date in dateBrackets){
    revenuesArray.push({
      timeRange : date,
      total : dateBrackets[date]
    })
  }

  var revenues = revenuesArray.map(function(element){
    return (
      <p key={element.timeRange}>{element.timeRange} : ${element.total}</p>
    )
  })

  return (
    <div className="accounting">
      <h1>Accounting</h1>
      <p>...</p>
      <p>...</p>
      {revenues}
    </div>
  )

};

export default Accounting;