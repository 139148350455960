import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NZ_GOWNING_STREET_PHONE_NUMBER } from '../../constants';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name : "",
      email : "",
      number : "",
      query : "",
      hasSubmitted : false,
      error : null
    };

  }

  handleChange(field, event) {
    var nextState = {};
    nextState[field] = event.target.value;

    this.setState(nextState);
  }

  handleSubmit(context) {
    if ( context.state.name === "" ||
         context.state.email === "" ||
         context.state.number === "" ||
         context.state.query === "")
    {
      context.setState({
        error : "Please fill in all fields"
      });
    }

    else {
      var subReq = new XMLHttpRequest();
      subReq.addEventListener("load", function(){

        if(this.status === 200) {
          context.setState({
            hasSubmitted : true
          });
        }
        else {
          context.setState({
            error : "Sorry, your request could not be submitted. Please send an email directly to support@graduationgowns.co.nz"
          });
        }

      });
      subReq.open("POST", "/api/contactform");
      subReq.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      subReq.send(JSON.stringify({
        name : context.state.name,
        email : context.state.email,
        number : context.state.number,
        query : context.state.query,
      }));

    }
  }
  render() {
    let formDisplay = null;

    if (!this.state.hasSubmitted){
      formDisplay = (
        <div className="contact-form">
          <input
            type="text"
            placeholder="Name*"
            onChange={this.handleChange.bind(this, "name")}
          />
          <input
            type="text"
            placeholder="Email*"
            onChange={this.handleChange.bind(this, "email")}
          />
          <input
            type="text"
            placeholder="Phone Number*"
            onChange={this.handleChange.bind(this, "number")}
          />
          <textarea
            placeholder="Your query*"
            onChange={this.handleChange.bind(this, "query")}
          />
          <button onClick={() => this.handleSubmit(this)}>Submit</button>
          <p className="error">{this.state.error}</p>
        </div>
      )
    }
    else {
      formDisplay = (
        <div className="thanksforsubmitting">
          <p>Thank you for submitting your enquiry.</p>
          <p>Our support team will respond to your query as soon as possible.</p>
          {/* <p>Please note that from Dec 21 - Jan 9, our head office will be closed. Orders will continue to ship but phone and email support will be delayed</p> */}
        </div>
      )
    }

    return (
      <div className="contact-us">
        <Helmet
          title="Contact Gowning Street NZ"
          meta={[
            {"name": "description", "content": "Contact Gowning Street New Zealand"},
          ]}
        />
        <div className="contact-content">
          <h1>Contact Us</h1>
          <p className="description">Have questions? Our support team is available to assist you, 9am - 5pm, Monday to Friday</p>
          <h2>Email</h2>
          <p className="email">support@graduationgowns.co.nz</p>
          <h2>Phone</h2>
          <p>{NZ_GOWNING_STREET_PHONE_NUMBER}</p>
          <h2>Contact Form</h2>
          {formDisplay}
          <h3>Connect with us on <a target="blank" href="https://www.facebook.com/gowningstreet/">Facebook</a><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165147/Utility/fb-logo.png"/></h3>
        </div>
      </div>
    );
  }
}

export default Contact;
