import React, { Component } from 'react';

class Order extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const toggleDetails = (event) => {
      var buttonId = event.target.id;
      var button = document.getElementById(buttonId);

      var display = document.getElementById(Number(buttonId) * -1);

      if(display.style.display === "none"){
        display.style.display = "";
        button.innerHTML = "-";
      }

      else {
        display.style.display = "none";
        button.innerHTML = "+";
      }

    };

    const toggleStatus = (event) => {
      var id = event.target.getAttribute('data-id');

      var xmlhttp = new XMLHttpRequest();
      xmlhttp.addEventListener("load", function(){
        if(JSON.parse(this.response).success === true){
          window.location = ("/admin");
        }
      });
      xmlhttp.open("PUT", "/api/adminapi/toggleOrder");
      xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xmlhttp.send(JSON.stringify({
        id:id
      }));
    };

    const orders = this.props.orders;

    let orderTable = null;

    if(orders) {
      orderTable = orders.map(function(element, index){

        if (index < 50) {

          var items = JSON.parse(element.items);

          var orderItems = items.map(function(order){
            return (
              <div key={order.id}>
                <p>{order.name}</p>
                <p>{order.cap}</p>
                <p>{order.gown}</p>
                <p>{order.hood}</p>
                <p>{order.qty}</p>
              </div>
            )
          })

          var hidden = {
            display: "none"
          }

          var id = element.id;

          return (
            <div key={element.id}>
              <p>Order Number: {element.ordernum}</p>
              <p>Status : {element.status}</p>
              <p>...</p>
              <p>Date : {element.createdAt}</p>
              <p>First Name : {element.first_name}</p>
              <p>Last Name : {element.last_name}</p>
              <p>${element.total}</p>
              <p>Source : {element.source}</p>
              <p>Comments : {element.comments}</p>
              <br />
              <button onClick={toggleDetails} id={id}>+</button>
              <div id={id*-1} style={hidden}>
                <p>Shipping Details:</p>
                <p>{element.shipping} shipping</p>
                <p>{element.address1}</p>
                <p>{element.address2}</p>
                <p>{element.postcode}</p>
                <p>{element.city}</p>
                <p>{element.state}</p>
                <p>{element.email}</p>
                <p>{element.phone}</p>
                <br />
                <p>Order Details</p>
                {orderItems}
              </div>
              <hr />
            </div>
          )

        } //if index < 50

        else {
          return;
        }

      }); //orders.map

    }//if orders

    return (
      <div className="order">
        <h1>Order</h1>
        {orderTable}
      </div>
    )

  }

} //Order

export default Order;
