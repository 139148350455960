import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Products from '../../sources/products';
import ShopItems from '../selections/shop-items';
import Description from '../selections/description';
import ProductDescriptions from '../../sources/product-description';

class Product extends Component {
  constructor(props) {
    super(props);
    const path = props.match.url;

    let altTag = "Graduation Gowns Display Image";
    if(Products[path].altTags){
      altTag = Products[path].altTags[0];
    }

    this.state = {
      image : Products.hasOwnProperty(path) ? Products[path].photos[0] : null,
      mainAlt : Products.hasOwnProperty(path) ? altTag : null,
      path : path
    };

    this.setPrimary = this.setPrimary.bind(this);

  }

  componentWillReceiveProps() {
    var path = window.location.pathname;

    var altTag = "Graduation Gowns Display Image";

    if(Products[path].altTags){
      altTag = Products[path].altTags[0];
    }

    this.setState({
      path : path,
      image : Products[path].photos[0],
      mainAlt : altTag
    });
  }

  setPrimary(event) {
    this.setState({
      image : event.target.id,
      mainAlt : event.target.alt
    });
  }

  renderDisplay(product, setPrimary) {
    const images = product.photos;
    const altTags = product.altTags;
    return images.map(function(element, index){
      // if(!altTags){
        return (
          <img
            src={element}
            alt="Graduation Gowns Display Image"
            className="image-mini"
            key={element}
            id={element}
            onClick={setPrimary}
          />
        )
    })
  }

  render() {
    const { path } = this.state;
    const product = Products[path]

    var isProducts = false;

    if (window.location.pathname.indexOf("products") !== -1){
      isProducts = true;
    }

    var titleTag = Products[path].name + " | " + "University Graduation Gown Set";

    if (isProducts){
      titleTag = "Gowning Street Graduation Regalia";
    }

    if(Products[path].titleTag){
      titleTag = Products[path].titleTag;
    }

    var metaTag = "Gowning Street provides quality, hand crafted graduation gown sets for New Zealand University students";

    if(Products[path].metaTag){
      metaTag = Products[path].metaTag;
    }

    return (
      <div className="product-set">
        <Helmet
          title={titleTag}
          meta={[
            {"name": "description", "content": metaTag},
          ]}
        />
        <div className="image-set">
          <div className="image-preview">
            {this.renderDisplay(product, this.setPrimary)}
          </div>
          <img
            src={this.state.image}
            id="image-main"
            alt={this.state.mainAlt}
          />
        </div>
        <h1>{Products[path].name}</h1>
        <h2 className="product-price">${Products[path].price}</h2>
        <ShopItems path={path}/>
        <Description path={path}/>
        <ProductDescriptions productInfo={Products[path]}/>

      </div>
    )
  }
}

export default Product;
