import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';
import { Link } from 'react-router-dom';
import { NZ_GOWNING_STREET_PHONE_NUMBER } from '../../constants';

const Background = styled.div`
  background: #F9F9F9;
  width: 100%;
`;

const Section = styled.div`
  display: block;
  margin: 0 auto;
  padding: 20px 40px;
  text-align: center;
  h2 {
    font-size: 36px;
    padding: 30px 0;
    line-height: 38px;
  }
  ${mediaQueries.mobile`
    max-width: 300px;

  `};
`

const Container = styled.div`
  max-width: 500px;
    margin: 0 auto;
  h3 {
    font-size: 24px;
    padding: 15px 0;
    line-height: 30px;
  }
  p {
    padding: 15px 0;
    line-height: 25px;
  }
`


const Details = () => {
  return (
    <Background>
      <Section className="section" style={{paddingTop: '30px'}}>
        <h2>NEW ZEALAND’S QUALITY GRADUATION GOWN FOR PURCHASE</h2>
        <Container>
          <h3>A brand you can trust</h3>
          <p>Gowning Street is a brand that prides itself on excellence, quality and trust.</p>
          <p>We are a brand that is trusted by both students and educational institutions for providing premium quality graduation regalia at great prices. Our values of excellence and superior workmanship is extended through our range of handcrafted graduation hat and gowns.</p>
        </Container>
        <Container>
          <h3>Our Workmanship</h3>
          <p>
            The New Zealand University academic hoods, hats and gowns are manufactured in accordance to strict University academic dress guidelines. You will find that our products provide a great fit and superior feel, ensuring you are looking fabulous at graduation ceremony.
          </p>
        </Container>
        <Container>
          <h3>Your graduation ceremony is a special moment</h3>
          <p>
            Finishing up at University is a momentous occasion.
            Your graduation ceremony is a time of celebration and also a time for reflection on what you have achieved.
            As you enter into the next stage of life, it is important to take a pause and enjoy the moment. At Gowning Street we can assist you with all of your graduation attire needs; whether it’s a <Link to="/regalia/bachelor-gown">bachelor gown</Link>, <Link to="/regalia/master-gown">master gown</Link> or even a <Link to="/regalia/graduation-teddy-bear">graduation teddy</Link>! We are there to make this occasion an event to cherish for the rest of your life.
          </p>
        </Container>
      </Section>
      <Section className="section">
        <h2>ACADEMIC GOWN HIRE v OUTRIGHT PURCHASE</h2>
        <Container>
          <p>You have two options when it comes to organizing your academic dress for your your graduation ceremony, you can buy or hire.</p>
          <p>You may think that hiring is the cheapest option, but you will be amazed to know at Gowning Street we are able to supply a premium-crafted academic gown for purchase at a more affordable price than hire (with much more convenience).</p>
          <p>Our range covers AUT and the University of Waikato and we also offer custom solutions.</p>
          <h2>THE ADVANTAGES OF BUYING YOUR GRADUATION GOWN</h2>

          <p>It's lifelong memorabilia and something you can pass down to your siblings or kids.</p>
          <p>If you have friends who are doing the same degree you can let them borrow it</p>
          <p>By getting together with your friends, you can take advantage of our students deals where you can save up to $10 per person on your academic dress.</p>
          <p>People that hire a gown, have to worry about staining/damage fees. You can relax on the day fully enjoying your celebration as you have made the smarter choice of purchasing your gown.</p>
          <p>No lining up for you academic dress hire– We will courier your gown straight to your address. Allowing you to take your graduation photography at a time convenient to you! </p>
        </Container>
      </Section>
      <Section className="section">
        <h2>NEW ZEALAND WIDE DELIVERY</h2>
        <Container style={{paddingBottom: '30px'}}>
          <p>For quick delivery of your graduation regalia order before 12pm for <Link to="/shipping">same day dispatch</Link>. In most cases you should receive your gown in 3-4 business days.</p>
          <p>If you are searching for a well-respected organisation to purchase your grad gown and hat, you’re bound to be extremely satisfied with our excellent quality and quick delivery time.</p>
          <p>If you need any further information regarding the gowns we have available, or you would like to speak to a staff member, please feel free to call us on {NZ_GOWNING_STREET_PHONE_NUMBER} <br />or email us at support@gowningstreet.com</p>
          <p>For overseas customers, we offer international shipping around the world. If you live in Australia, please visit <a href="https://www.gowningstreet.com.au">www.gowningstreet.com.au</a>, or <a href="https://www.gowningstreet.co.uk">www.gowningstreet.co.uk</a> if you live in the UK</p>
        </Container>
      </Section>
    </Background>
  )

}

export default Details;




