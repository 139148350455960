import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { connect } from 'react-redux';

class Thankyou extends Component {
  componentDidMount() {
    this.props.dropCart();
  }

  render() {
    const query =  window.location.search.substring(1).split("&");

    const queries = {};

    query.forEach(function(element){
      const pair = element.split("=");

      queries[pair[0]] = pair[1];
    });

    if(process.env.NODE_ENV === "production"){
      this.props.dropCart();
    }

    return (
      <div className="thankyou">
        <Helmet
          title="Thank You for Choosing Gowning Street | Gowning Street"
          meta={[
            {"name": "description", "content": "Gowning Street order confirmation - thank you for choosing Gowning Street"},
          ]}
        />
        <h1>Thank You</h1>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Graduates celebrating"/>
        <p>Your Order Number is:</p>
        <p>{queries.ordernum}</p>
        <p>Your order details have been sent to the email address you have provided.</p>
        <p>Important: If you have not received an email confirmation within a few hours, please contact us on support@graduationgowns.co.nz with the above order number as reference.</p>
        <p>You will be sent a separate email with your tracking number once your order has been dispatched. Please allow 1-2 business days.</p>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dropCart: () => {
      dispatch({
        type: 'EMPTY_CART'
      })
    },
    dropCustomer: () => {
      dispatch({
        type: 'DROP_CUSTOMER'
      })
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Thankyou);
