import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  BACHELOR_GOWN_PRICE,
  BACHELOR_SET_PRICE,
  BACHELOR_GOWN_CAP_PRICE,
  CAP_PRICE,
  GARMENT_BAG,
  HOOD_PRICE,
  MASTER_GOWN_CAP_PRICE,
  MASTER_GOWN_PRICE,
  PHD_BONNET_PRICE,
  PHD_GOWN_PRICE,
  PUPPY_LARGE_PRICE,
  PUPPY_SMALL_PRICE,
  TEDDY_SMALL_PRICE
} from '../../constants'

const Buy = () => {

  return (
    <div className="shop-products">
      <Helmet
        title="Buy Graduation Gown Sets New Zealand | Graduation Gowns, Hoods, Hats, Gifts and Frames"
        meta={[
          {"name": "description", "content": "Buy premium graduation gown sets, including Bachelor and Master Style gowns, hoods and hats"},
        ]}
      />
      <h1>Buy Graduation Gown Sets, Regalia, Gifts and Frames</h1>
      <div className="subheading">
        <h2>Hand Crafted Regalia for University, College and School Graduations</h2>
      </div>
      <div className="catalogue">
        <div className="img-container">
          <div className="img-box">
            <Link to="/waikato-uni">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Waikato Uni Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/aut">
            <div className="img-unit">
              <div className="fixed-height-img">
                <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Regalia%20Photos/AUT/aut-mast-arts-side.jpg" alt="Full university graduation gown set"/>
              </div>
              <div className="fixed-height-text">
                <strong className="item-name">AUT Graduation Gowns</strong>
                <p>${BACHELOR_SET_PRICE}</p>
              </div>
              <button>Buy now</button>
            </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/auckland-uni">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Auckland Uni Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/bachelor-gown-set">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg" alt="Bachelor style gown and hat set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bachelor Gown & Hat Set</strong>
                  <p>${BACHELOR_GOWN_CAP_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/master-gown-set">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Regalia/master-cap-gown-set.jpg" alt="Master style gown and hat set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Master Gown & Hat Sets</strong>
                  <p>${MASTER_GOWN_CAP_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/bachelor-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg" alt="Bachelor style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Gowns - <br />Bachelor Style</strong>
                  <p>${BACHELOR_GOWN_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/master-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg" alt="Master style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Gowns - <br />Master Style</strong>
                  <p>${MASTER_GOWN_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-hoods">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/hood.jpg" alt="Academic hood"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Academic Hoods</strong>
                  <p>${HOOD_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/victoria-university-of-wellington">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">VUW Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/canterbury-uni">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Canterbury Uni Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/massey-uni">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Massey Uni Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/lincoln-uni">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Lincoln Uni Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/university-of-otago">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">University of Otago Graduation Gowns</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-puppy-small">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Small</strong>
                  <p>${PUPPY_SMALL_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-puppy-large">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Large</strong>
                  <p>${PUPPY_LARGE_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-teddy-bear">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" alt="graduation bear"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Teddy Bear</strong>
                  <p>${TEDDY_SMALL_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-hat">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg" alt="graduation hat"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Hats</strong>
                  <p>${CAP_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>NEW ZEALAND'S BEST GRADUATION GOWN</h2>
          <p>The time has come for graduation ceremony, all the many years of study have brought you to this moment. It’s a time for celebration and getting together with those special people who helped you along the way!
          </p>
          <p>When you’re looking for somewhere to buy an academic gown for the graduation ceremony, you have the option of either hiring your academic dress or buying. So what is the better option?</p>
          <p>Surprisingly, buying a hat and gown set can actually work out cheaper than getting a rental. You will have the flexibility of taking your graduation photography at a time convenient to you and can avoid the long line of people waiting to pick up their hire-gown on the day of graduation. Moreover, given the special significance of your achievement, your academic gown is something you will want to hold onto forever</p>
          <h2>WE CATER TO SEVERAL DIFFERENT ACADEMIC INSTITUTIONS IN NEW ZEALAND</h2>
          <p>Whether you are graduating from University or you are a teacher needing a gown for your formal assemblies, we can supply for a variety of occasions. Our graduation gowns are all manufactured to superior cut and finish and can be <Link to="/shipping">delivered anywhere in New Zealand.</Link></p>
          <p>You can bulk purchase graduation gowns that are completely customised to your institute – pick the fabric, style and colours that represent your school and create a unique, world-class gown that represents the premium brand of your school!</p>
          <p>We have wholesale options, check our Wholesale page, for <Link to="/wholesale">bulk purchases</Link> or if you are student, you can get together to take advantage of our student deals where you can <Link to="/save-with-friends">save up to $10 per gown.</Link></p>
          <h2>ASK ABOUT OUR OTHER PRODUCTS</h2>
          <p>Our range also extends to graduation frames and grad teddy bears, we offer fast delivery and same day dispatch for orders received before 12pm. See our <Link to="/shipping">shipping page</Link></p>
          <p>If you’re after any further information regarding our graduation regalia that we have available, please visit our <Link to="/contact">contact us</Link> page</p>
        </div>
      </div>
    </div>
  )
};

export default Buy;
