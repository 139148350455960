import React from 'react';
import styled from 'styled-components'
import { Column, Container } from '../../components/Layout';
import { AlphaHeader, BetaHeader } from '../../components/Section';
import { sizes } from '../../utils/theme'
import { mediaQueries } from '../../utils/mediaQueries';

const reviews = [
  {
    id: '1',
    author: 'Ashley',
    header: 'Excellent Quality',
    description: 'My brand new gown was of excellent quality and looked amazing on my graduation day!',
    rating: 5
  },
  {
    id: '2',
    author: 'Hugh',
    header: 'Fast & Efficient',
    description: 'I ordered my gown set and received it the next business day. This meant that I didn\'t need to wait hours for a rental gown on the day!',
    rating: 5
  },
  {
    id: '3',
    author: 'Jo',
    header: 'Great Service',
    description: 'The Gowning Street team were very professional and really looked after our school\'s requirements',
    rating: 5
  },
];


const createStars = (num) => {
  const starArr = [];
  for (let i = 0; i < num; i++) {
    starArr.push(<i className="fas fa-star" key={i} style={{color: '#DC572B'}}></i>)
  }
  return starArr
}

const StyledHeader = styled(AlphaHeader)`
  padding: 40px 18px;
`

const SectionConainerGrey = styled.div`
  padding: 30px 15px 80px 15px;
  background-color: #F9F9F9;
`

const ReviewTitle = styled.div`
  min-height: 2rem;
  padding: 2px;
`

const ReviewContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: .125rem;
  box-shawdow: 0 .0625rem .625rem 0 rgba(#031634, .2);
  outline: 0;
  padding: 0.2rem;
  margin: 30px auto;
  text-align: center;
  display: flex;
  alignContent: center;
  alignItems: center;
  flex-direction: column;
  height: 272px;
  width: 220px;
  box-shadow: 1px 2px 5px #888888;
  ${mediaQueries.mobile`
     margin: 15px auto;
  `};
`

const StarContainer = styled.div`
  padding: 5px;
  margin-top: 5px;
`

const SetHeightParagraph = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 14rem;
  height: 14rem;
  padding: 0.25rem 1rem;
  p {
    line-height: 20px;
    margin-top: 5px;
    padding: 20px 5px;
  }
`
const StyledStepRow = styled.div`
  display:flex;
  flex-flow: row wrap;
  width: 80%;
  margin: 0 auto;
`

const Reviews = ({ reviews }) => (
  <StyledStepRow>
    {
      reviews.map((review) => (
        <Column key={review.id}>
          <ReviewContainer>
            <SetHeightParagraph>
              <ReviewTitle>
                <BetaHeader>{ review.header }</BetaHeader>
              </ReviewTitle>
                <StarContainer>
                { createStars(review.rating) }
                </StarContainer>
                <p>{ review.description }</p>
            </SetHeightParagraph>
            <p>{ review.author }</p>
          </ReviewContainer>
        </Column>
        )
      )
    }
  </StyledStepRow>
)


const Testimonials = ({ children }) => {
  return (
    <SectionConainerGrey>
        <StyledHeader>Our Graduates tell it like it is</StyledHeader>
        <Reviews reviews={reviews} />
        { children }
    </SectionConainerGrey>
  )
}

export default Testimonials;
