import React from 'react';
import { Link } from 'react-router-dom';

const menuLinks = {
  shop: [
    {link: '/regalia', name: 'All Regalia'},
    {link: '/regalia/bachelor-gown-set', name: 'Bachelor Gown Sets'},
    {link: '/regalia/bachelor-gown', name: 'Bachelor Gown'},
    {link: '/regalia/master-gown-set', name: 'Master Gown Sets'},
    {link: '/regalia/master-gown', name: 'Master Gown'},
    {link: '/regalia/graduation-hat', name: 'Graduation Hat'},
    {link: '/regalia/graduation-hoods', name: 'Graduation Hoods'},
    {link: '/graduation-gift', name: 'Graduation Gifts'},
    {link: '/certificate-frame', name: 'Certificate Frames'},
    {link: '/legal-wear', name: 'Legal Wear'},
    {link: '/wholesale', name: 'Wholesale'},
  ],
  uniSets: [
    {link: '/auckland-uni', name: 'Auckland Uni'},
    {link: '/aut', name: 'AUT'},
    {link: '/waikato-uni', name: 'Waikato Uni'},
    {link: '/lincoln-uni', name: 'Lincoln Uni'},
    {link: '/massey-uni', name: 'Massey Uni'},
    {link: '/university-of-otago', name: 'Otago Uni'},
    {link: '/victoria-university-of-wellington', name: 'VUW'},
    {link: '/wintec', name: 'Wintec'},
    {link: '/wholesale', name: 'Wholesale'},
  ],
  frames: [
    {link: '/certificate-frame/auckland-uni', name: 'Auckland Uni'},
    {link: '/certificate-frame/aut', name: 'AUT'},
    {link: '/certificate-frame/canterbury-uni', name: 'Canterbury'},
    {link: '/certificate-frame/lincoln-uni', name: 'Lincoln Uni'},
    {link: '/certificate-frame/massey-uni', name: 'Massey Uni'},
    {link: '/certificate-frame/mit-manukau', name: 'MIT Manukau'},
    {link: '/certificate-frame/nmit', name: 'NMIT'},
    {link: '/certificate-frame/open-polytechnic', name: 'Open Polytechnic'},
    {link: '/certificate-frame/university-of-otago', name: 'Otago'},
    {link: '/certificate-frame/toi-ohomai-tech', name: 'Toi Ohomai'},
    {link: '/certificate-frame/victoria-university-of-wellington', name: 'VUW'},
    {link: '/certificate-frame/waikato', name: 'Waikato'},
    {link: '/certificate-frame/whitireia-weltec', name: 'Whitireia Weltec'},
    {link: '/certificate-frame/wintec', name: 'Wintec'},
  ],
  about: [
    {link: '/about', name: 'About Us'},
    {link: '/save-with-friends', name: 'Save With Friends'},
    {link: '/shipping', name: 'Shipping Estimates'},
    {link: '/pricebeat', name: 'Price Beat'},
    {link: '/faq', name: 'FAQ'},
    {link: '/contact', name: 'Contact Us'},
  ]
}

const Submenu = ({ menuTitle, handleLeave }) => {
  return (
    <div className="nav__submenu" key={menuTitle} onClick={handleLeave}>
      {
        menuLinks[menuTitle].map(({ link, name }) => (
          <Link to={link} className="nav__submenu-item" key={link}>
            { name }
          </Link>
        ))
      }
    </div>
  )
}

export default Submenu;
