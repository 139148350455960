import React from 'react';
import { gownCap } from '../../sources/gowncap';

const constructOptions = (code, index, handleSelection) => {
  const family = code.substring(0,2)
  const identifier = code[6]
  const result = [{key : "no_exchange", display: "No exchange"}];
  for(let key in gownCap) {
    if (key.includes(family) && key[6] === identifier) {
      if (code !== key) {
        result.push({ key, display: gownCap[key] })
      }
    }

  }
  const options = result.map(function(option) {
    return <option key={option.key} value={option.key}>{option.display}</option>
  })
  return (
    <select id={index} onChange={(e) => handleSelection(e, code, index)}>
      {options}
    </select>
  )
}

function ExchangeSelections({ toExchange, handleSelection }) {
  const exchangeTable = toExchange.map(({ gown, cap, name }, index) => (
    <div className="orderblock" key={`${index}-${name}`}>
      { gown &&
      <div className="row">
        <div className="col1">
          {gownCap[gown]}
        </div>
        <div className="col2">
          {constructOptions(gown, "gown" + index, handleSelection)}
        </div>
      </div>
    }
    { cap &&
      <div className="row">
        <div className="col1">
          {gownCap[cap]}
        </div>
        <div className="col2">
          {constructOptions(cap, "hat" + index, handleSelection)}
        </div>
      </div>
    }
    </div>
  ))
  return <div className="orderList">{exchangeTable}</div>
}

export default ExchangeSelections;