const prices = require('../../constants')

const {
  BACHELOR_SET_PRICE,
  BACHELOR_GOWN_CAP_PRICE,
  BACHELOR_GOWN_PRICE,
  MASTER_SET_PRICE,
  MASTER_GOWN_CAP_PRICE,
  MASTER_GOWN_PRICE,
  HOOD_PRICE,
  CAP_PRICE,
  TEDDY_SMALL_PRICE,
  PUPPY_SMALL_PRICE,
  PUPPY_LARGE_PRICE,
  BARRISTER_GOWN_PRICE,
  ADMISSIONS_GOWN_PRICE,
  BARRISTER_WIG_PRICE
} = prices

var Products = {
  // Waikato

  "/waikato-uni/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165137/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-all-back.jpg"],
    "metaTag" : "Buy your Waikato Uni graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Waikato Uni graduation gown set - All Bachelor Degrees - Front view", "Waikato Uni graduation gown set - All Bachelor Degrees - Side view", "Waikato Uni graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Waikato Uni Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Waikato Hood - All Bachelor Degrees",
    "hood-style" : "Black hood with gold lining",
    "colour" : "Gold lining",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ10101",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow"]
  },
  "/waikato-uni/bachelor-degrees-honours" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-hons-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Uni%20Regalia%20Photos/Waikato/waikato-bach-hons-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-bach-hons-back.jpg"],
    "metaTag" : "Buy your Waikato Uni graduation gown set for bachelor honours degrees with Gowning Street",
    "altTags" : ["Waikato Uni graduation gown set - All Bachelor Degrees - Front view", "Waikato Uni graduation gown set - All Bachelor Degrees - Side view", "Waikato Uni graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Waikato Uni Graduation Gown Set - All Bachelor Degrees (Honours)",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Waikato Hood - All Bachelor Degrees (Honours)",
    "hood-style" : "Black hood with gold lining and gold border",
    "colour" : "Gold lining and gold border",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ10102",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow"]
  },
  "/waikato-uni/master-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-mast-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165136/Uni%20Regalia%20Photos/Waikato/waikato-mast-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165137/Uni%20Regalia%20Photos/Waikato/waikato-mast-all-back.jpg"],
    "metaTag" : "Buy your Waikato graduation gown set for all master degrees with gowning street",
    "altTags" : ["Waikato Uni graduation gown set - All Master Degrees - Front view", "Waikato Uni graduation gown set - All Master Degrees - Side view", "Waikato Uni graduation gown set - All Master Degrees - Back view"],
    "name" : "Waikato Uni Graduation Gown Set - All Master Degrees",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Waikato Hood - All Master Degrees",
    "hood-style" : "Gold hood with gold lining",
    "colour" : "Gold lining",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ10103",
    "productType" : "master-full-set",
    "colors" : ["yellow"]
  },

//wintec

  "/wintec/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535093/Uni%20Regalia%20Photos/Wintec/wintec-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535093/Uni%20Regalia%20Photos/Wintec/wintec-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535093/Uni%20Regalia%20Photos/Wintec/wintec-bach-all-back.jpg"],
    "metaTag" : "Buy your Wintec graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Wintec graduation gown set - All Bachelor Degrees - Front view", "Wintec graduation gown set - All Bachelor Degrees - Side view", "Wintec graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Wintec Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Wintec Hood - All Bachelor Degrees",
    "hood-style" : "Black hood with blue lining",
    "colour" : "Blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20301",
    "productType" : "bachelor-full-set",
    "colors" : ["blue"]
  },

//Auckland Uni

  "/auckland-uni/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592546458/Uni%20Regalia%20Photos/Auckland/auckland-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592546458/Uni%20Regalia%20Photos/Auckland/auckland-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592546458/Uni%20Regalia%20Photos/Auckland/auckland-bach-arts-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Arts with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Arts - Front view", "Auckland Uni graduation gown set - Bachelor of Arts - Side view", "Auckland Uni graduation gown set - Bachelor of Arts - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Arts",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Arts",
    "hood-style" : "Cambridge style, lined with pink satin and white fur",
    "colour" : "Pink satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20201",
    "productType" : "bachelor-full-set",
    "colors" : ["pink"]
  },
  "/auckland-uni/bachelor-of-commerce" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592546369/Uni%20Regalia%20Photos/Auckland/auckland-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592546369/Uni%20Regalia%20Photos/Auckland/auckland-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592546369/Uni%20Regalia%20Photos/Auckland/auckland-bach-comm-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Commerce with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Commerce - Front view", "Auckland Uni graduation gown set - Bachelor of Commerce - Side view", "Auckland Uni graduation gown set - Bachelor of Commerce - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Commerce",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Commerce",
    "hood-style" : "Cambridge style, lined with orange satin and white fur",
    "colour" : "Orange satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20202",
    "productType" : "bachelor-full-set",
    "colors" : ["orange"]
  },
  "/auckland-uni/bachelor-of-architectural-studies" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535834/Uni%20Regalia%20Photos/Auckland/auckland-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535834/Uni%20Regalia%20Photos/Auckland/auckland-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535834/Uni%20Regalia%20Photos/Auckland/auckland-bach-arch-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Architectural Studies with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Architectural Studies - Front view", "Auckland Uni graduation gown set - Bachelor of Architectural Studies - Side view", "Auckland Uni graduation gown set - Bachelor of Architectural Studies - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Architectural Studies",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Architectural Studies",
    "hood-style" : "Cambridge style, lined with lemon satin and white fur",
    "colour" : "Lemon satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20203",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow"]
  },
  "/auckland-uni/bachelor-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535737/Uni%20Regalia%20Photos/Auckland/auckland-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535737/Uni%20Regalia%20Photos/Auckland/auckland-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535737/Uni%20Regalia%20Photos/Auckland/auckland-bach-educ-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Education with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Education - Front view", "Auckland Uni graduation gown set - Bachelor of Education - Side view", "Auckland Uni graduation gown set - Bachelor of Education - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Education",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Education",
    "hood-style" : "Cambridge style, lined with green satin and white fur",
    "colour" : "Green satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20204",
    "productType" : "bachelor-full-set",
    "colors" : ["green"]
  },
  "/auckland-uni/bachelor-of-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535670/Uni%20Regalia%20Photos/Auckland/auckland-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535670/Uni%20Regalia%20Photos/Auckland/auckland-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535670/Uni%20Regalia%20Photos/Auckland/auckland-bach-engg-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Engineering with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Engineering - Front view", "Auckland Uni graduation gown set - Bachelor of Engineering - Side view", "Auckland Uni graduation gown set - Bachelor of Engineering - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Engineering",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Engineering",
    "hood-style" : "Cambridge style, lined with dark violet satin and white fur",
    "colour" : "Violet satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20205",
    "productType" : "bachelor-full-set",
    "colors" : ["purple"]
  },
  "/auckland-uni/bachelor-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535577/Uni%20Regalia%20Photos/Auckland/auckland-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535577/Uni%20Regalia%20Photos/Auckland/auckland-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535577/Uni%20Regalia%20Photos/Auckland/auckland-bach-law-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Law with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Law - Front view", "Auckland Uni graduation gown set - Bachelor of Law - Side view", "Auckland Uni graduation gown set - Bachelor of Law - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Law",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Law",
    "hood-style" : "Cambridge style, lined with light blue satin and white fur",
    "colour" : "Light blue satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20206",
    "productType" : "bachelor-full-set",
    "colors" : ["lightblue"]
  },
  "/auckland-uni/bachelor-of-health-sciences" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535490/Uni%20Regalia%20Photos/Auckland/auckland-bach-healthsci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535490/Uni%20Regalia%20Photos/Auckland/auckland-bach-healthsci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535490/Uni%20Regalia%20Photos/Auckland/auckland-bach-healthsci-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Health Sciences with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Health Sciences - Front view", "Auckland Uni graduation gown set - Bachelor of Health Sciences - Side view", "Auckland Uni graduation gown set - Bachelor of Health Sciences - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Health Sciences",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Health Sciences",
    "hood-style" : "Cambridge style, lined with lilac satin and white fur",
    "colour" : "Lilac satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20207",
    "productType" : "bachelor-full-set",
    "colors" : ["lightblue"]
  },
  "/auckland-uni/bachelor-of-nursing" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535319/Uni%20Regalia%20Photos/Auckland/auckland-bach-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535319/Uni%20Regalia%20Photos/Auckland/auckland-bach-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535319/Uni%20Regalia%20Photos/Auckland/auckland-bach-nurse-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Nursing with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Nursing - Front view", "Auckland Uni graduation gown set - Bachelor of Nursing - Side view", "Auckland Uni graduation gown set - Bachelor of Nursing - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Nursing",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Nursing",
    "hood-style" : "Cambridge style, lined with navy blue and white fur",
    "colour" : "Navy blue satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20208",
    "productType" : "bachelor-full-set",
    "colors" : ["#000080"]
  },
  "/auckland-uni/bachelor-of-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592535209/Uni%20Regalia%20Photos/Auckland/auckland-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535209/Uni%20Regalia%20Photos/Auckland/auckland-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592535209/Uni%20Regalia%20Photos/Auckland/auckland-bach-sci-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Bachelor of Science with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Bachelor of Science - Front view", "Auckland Uni graduation gown set - Bachelor of Science - Side view", "Auckland Uni graduation gown set - Bachelor of Science - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Bachelor of Science",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Bachelor of Science",
    "hood-style" : "Cambridge style, lined with dark blue and white fur",
    "colour" : "Dark blue satin and white fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20209",
    "productType" : "bachelor-full-set",
    "colors" : ["#001a33"]
  },

  "/auckland-uni/master-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534965/Uni%20Regalia%20Photos/Auckland/auckland-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534965/Uni%20Regalia%20Photos/Auckland/auckland-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534965/Uni%20Regalia%20Photos/Auckland/auckland-mast-arts-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Arts with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Arts - Front view", "Auckland Uni graduation gown set - Master of Arts - Side view", "Auckland Uni graduation gown set - Master of Arts - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Arts",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Arts",
    "hood-style" : "Cambridge style, lined with pink satin",
    "colour" : "Pink satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20211",
    "productType" : "master-full-set",
    "colors" : ["pink"]
  },
  "/auckland-uni/master-of-commerce" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534758/Uni%20Regalia%20Photos/Auckland/auckland-mast-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534758/Uni%20Regalia%20Photos/Auckland/auckland-mast-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534758/Uni%20Regalia%20Photos/Auckland/auckland-mast-comm-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Commerce with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Commerce - Front view", "Auckland Uni graduation gown set - Master of Commerce - Side view", "Auckland Uni graduation gown set - Master of Commerce - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Commerce",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Commerce",
    "hood-style" : "Cambridge style, lined with orange satin",
    "colour" : "Orange satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20212",
    "productType" : "master-full-set",
    "colors" : ["orange"]
  },
  "/auckland-uni/master-of-architectural-studies" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534582/Uni%20Regalia%20Photos/Auckland/auckland-mast-arhc-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534582/Uni%20Regalia%20Photos/Auckland/auckland-mast-arhc-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534582/Uni%20Regalia%20Photos/Auckland/auckland-mast-arhc-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Architectural Studies with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Architectural Studies - Front view", "Auckland Uni graduation gown set - Master of Architectural Studies - Side view", "Auckland Uni graduation gown set - Master of Architectural Studies - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Architectural Studies",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Architectural Studies",
    "hood-style" : "Cambridge style, lined with lemon satin",
    "colour" : "Lemon satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20213",
    "productType" : "master-full-set",
    "colors" : ["yellow"]
  },
  "/auckland-uni/master-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534680/Uni%20Regalia%20Photos/Auckland/auckland-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534680/Uni%20Regalia%20Photos/Auckland/auckland-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534680/Uni%20Regalia%20Photos/Auckland/auckland-mast-educ-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Education with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Education - Front view", "Auckland Uni graduation gown set - Master of Education - Side view", "Auckland Uni graduation gown set - Master of Education - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Education",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Education",
    "hood-style" : "Cambridge style, lined with green satin",
    "colour" : "Green satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20214",
    "productType" : "master-full-set",
    "colors" : ["green"]
  },
  "/auckland-uni/master-of-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008396/Aus-Uni-Photos/UON/uon-mast-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008397/Aus-Uni-Photos/UON/uon-mast-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008395/Aus-Uni-Photos/UON/uon-mast-it-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Engineering with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Engineering - Front view", "Auckland Uni graduation gown set - Master of Engineering - Side view", "Auckland Uni graduation gown set - Master of Engineering - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Engineering",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Engineering",
    "hood-style" : "Cambridge style, lined with dark violet satin",
    "colour" : "Violet satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20215",
    "productType" : "master-full-set",
    "colors" : ["purple"]
  },
  "/auckland-uni/master-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635637963/Uni%20Regalia%20Photos/Auckland/auckland-mast-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635637931/Uni%20Regalia%20Photos/Auckland/auckland-mast-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635637963/Uni%20Regalia%20Photos/Auckland/auckland-mast-law-back.png"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Law with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Law - Front view", "Auckland Uni graduation gown set - Master of Law - Side view", "Auckland Uni graduation gown set - Master of Law - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Law",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Law",
    "hood-style" : "Cambridge style, lined with light blue satin",
    "colour" : "Light blue satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20216",
    "productType" : "master-full-set",
    "colors" : ["lightblue"]
  },
  "/auckland-uni/master-of-health-sciences" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534852/Uni%20Regalia%20Photos/Auckland/auckland-mast-healthsci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534852/Uni%20Regalia%20Photos/Auckland/auckland-mast-healthsci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534852/Uni%20Regalia%20Photos/Auckland/auckland-mast-healthsci-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Health Sciences with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Health Sciences - Front view", "Auckland Uni graduation gown set - Master of Health Sciences - Side view", "Auckland Uni graduation gown set - Master of Health Sciences - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Health Sciences",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Health Sciences",
    "hood-style" : "Cambridge style, lined with lilac satin",
    "colour" : "Lilac satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20217",
    "productType" : "master-full-set",
    "colors" : ["lightblue"]
  },
  "/auckland-uni/master-of-nursing" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534348/Uni%20Regalia%20Photos/Auckland/auckland-mast-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534348/Uni%20Regalia%20Photos/Auckland/auckland-mast-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534348/Uni%20Regalia%20Photos/Auckland/auckland-mast-nurse-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Nursing with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Nursing - Front view", "Auckland Uni graduation gown set - Master of Nursing - Side view", "Auckland Uni graduation gown set - Master of Nursing - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Nursing",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Nursing",
    "hood-style" : "Cambridge style, lined with navy blue",
    "colour" : "Navy blue satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20218",
    "productType" : "master-full-set",
    "colors" : ["#000080"]
  },
  "/auckland-uni/master-of-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592534302/Uni%20Regalia%20Photos/Auckland/auckland-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534302/Uni%20Regalia%20Photos/Auckland/auckland-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592534302/Uni%20Regalia%20Photos/Auckland/auckland-mast-sci-back.jpg"],
    "metaTag" : "Buy your Auckland Uni graduation gown set for Master of Science with Gowning Street",
    "altTags" : ["Auckland Uni graduation gown set - Master of Science - Front view", "Auckland Uni graduation gown set - Master of Science - Side view", "Auckland Uni graduation gown set - Master of Science - Back view"],
    "name" : "Auckland Uni Graduation Gown Set - Master of Science",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Auckland Hood - Master of Science",
    "hood-style" : "Cambridge style, lined with dark blue",
    "colour" : "Dark blue satin",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20219",
    "productType" : "master-full-set",
    "colors" : ["#001a33"]
  },


  //AUT

  "/aut/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Uni%20Regalia%20Photos/AUT/aut-bach-arts-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Arts graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Arts graduation gown set - Front view", "AUT Bachelor of Arts graduation gown set - Side view", "AUT Bachelor of Arts graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Arts",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Arts",
    "hood-style" : "Black with bone coloured lining, edged with red",
    "colour" : "Lined with White and Red",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ21101",
    "productType" : "bachelor-full-set",
    "colors" : ["red"]
  },
  "/aut/bachelor-of-business" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Regalia%20Photos/AUT/aut-bach-buss-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Uni%20Regalia%20Photos/AUT/aut-bach-buss-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-buss-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Business graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Business graduation gown set - Front view", "AUT Bachelor of Business graduation gown set - Side view", "AUT Bachelor of Business graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Business",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Business",
    "hood-style" : "Black with bone coloured lining, edged with green",
    "colour" : "Lined with White and Green",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ21102",
    "productType" : "bachelor-full-set",
    "colors" : ["green"]
  },
  "/aut/bachelor-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Uni%20Regalia%20Photos/AUT/aut-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Uni%20Regalia%20Photos/AUT/aut-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-educ-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Edcuation graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Edcuation graduation gown set - Front view", "AUT Bachelor of Edcuation graduation gown set - Side view", "AUT Bachelor of Edcuation graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Edcuation",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Edcuation",
    "hood-style" : "Black with bone coloured lining, edged with fuschia",
    "colour" : "Lined with White and Fuschia",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20103",
    "productType" : "bachelor-full-set",
    "colors" : ["#cc00cc"]
  },
  "/aut/bachelor-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Uni%20Regalia%20Photos/AUT/aut-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Uni%20Regalia%20Photos/AUT/aut-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-law-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Law graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Law graduation gown set - Front view", "AUT Bachelor of Law graduation gown set - Side view", "AUT Bachelor of Law graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Law",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Law",
    "hood-style" : "Black with bone coloured lining, edged with forest green",
    "colour" : "Lined with White and Forest Green",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20104",
    "productType" : "bachelor-full-set",
    "colors" : ["green"]
  },
  "/aut/bachelor-of-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Uni%20Regalia%20Photos/AUT/aut-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Uni%20Regalia%20Photos/AUT/aut-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Uni%20Regalia%20Photos/AUT/aut-bach-engg-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Engineering graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Engineering graduation gown set - Front view", "AUT Bachelor of Engineering graduation gown set - Side view", "AUT Bachelor of Engineering graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Engineering",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Engineering",
    "hood-style" : "Black with bone coloured lining, edged with yellow",
    "colour" : "Lined with White and Yellow",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ21105",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow"]
  },
  "/aut/bachelor-conjoint-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Uni%20Regalia%20Photos/AUT/aut-bach-conjoint-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Uni%20Regalia%20Photos/AUT/aut-bach-conjoint-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Uni%20Regalia%20Photos/AUT/aut-bach-conjoint-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor Conjoint Degrees graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor Conjoint Degrees graduation gown set - Front view", "AUT Bachelor Conjoint Degrees graduation gown set - Side view", "AUT Bachelor Conjoint Degrees graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor Conjoint Degrees",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor Conjoint Degrees",
    "hood-style" : "Black with bone coloured lining, edged with maroon",
    "colour" : "Lined with White and Maroon",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20106",
    "productType" : "bachelor-full-set",
    "colors" : ["red"]
  },
  "/aut/bachelor-of-comp-info-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165128/Uni%20Regalia%20Photos/AUT/aut-bach-comp-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-comp-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Regalia%20Photos/AUT/aut-bach-comp-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Computer & Information Sciences graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Computer & Information Sciences graduation gown set - Front view", "AUT Bachelor of Computer & Information Sciences graduation gown set - Side view", "AUT Bachelor of Computer & Information Sciences graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Computer & Information Sciences",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Computer & Information Sciences",
    "hood-style" : "Black with bone coloured lining, edged with light green",
    "colour" : "Lined with White and Light Green",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ20107",
    "productType" : "bachelor-full-set",
    "colors" : ["green"]
  },
  "/aut/bachelor-of-health-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592547064/Uni%20Regalia%20Photos/AUT/aut-bach-healthsci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592547064/Uni%20Regalia%20Photos/AUT/aut-bach-healthsci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592547064/Uni%20Regalia%20Photos/AUT/aut-bach-healthsci-back.jpg"],
    "metaTag" : "Buy your AUT Bachelor of Health Science graduation gown set from Gowning Street!",
    "altTags" : ["AUT Bachelor of Health Science graduation gown set - Front view", "AUT Bachelor of Health Science graduation gown set - Side view", "AUT Bachelor of Health Science graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Bachelor of Health Science",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Bachelor of Health Science",
    "hood-style" : "Black with bone coloured lining, edged with Blue",
    "colour" : "Lined with White and Blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOZ21108",
    "productType" : "bachelor-full-set",
    "colors" : ["blue"]
  },
  "/aut/master-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Uni%20Regalia%20Photos/AUT/aut-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592529999/Uni%20Regalia%20Photos/AUT/aut-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592529999/Uni%20Regalia%20Photos/AUT/aut-mast-educ-back.jpg"],
    "metaTag" : "Buy your AUT Master of Education graduation gown set from Gowning Street!",
    "altTags" : ["AUT Master of Education graduation gown set - Front view", "AUT Master of Education graduation gown set - Side view", "AUT Master of Education graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Master of Education",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Master of Education",
    "hood-style" : "Black, lined with Fuschia",
    "colour" : "Fully Lined with Fuschia",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20111",
    "tassel" : "TAS00001",
    "productType" : "aut-master-full-set",
    "colors" : ["#ff00ff"]
  },
  "/aut/master-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592530183/Uni%20Regalia%20Photos/AUT/aut-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592530183/Uni%20Regalia%20Photos/AUT/aut-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592530183/Uni%20Regalia%20Photos/AUT/aut-mast-arts-back.jpg"],
    "metaTag" : "Buy your AUT Master of Arts graduation gown set from Gowning Street!",
    "altTags" : ["AUT Master of Arts graduation gown set - Front view", "AUT Master of Arts graduation gown set - Side view", "AUT Master of Arts graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Master of Arts",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Master of Arts",
    "hood-style" : "Black, lined with Red",
    "colour" : "Fully Lined with Red",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20112",
    "tassel" : "TAS00001",
    "productType" : "aut-master-full-set",
    "colors" : ["red"]
  },
  "/aut/master-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592530296/Uni%20Regalia%20Photos/AUT/aut-mast-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592530296/Uni%20Regalia%20Photos/AUT/aut-mast-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592530296/Uni%20Regalia%20Photos/AUT/aut-mast-law-back.jpg"],
    "metaTag" : "Buy your AUT Master of Law graduation gown set from Gowning Street!",
    "altTags" : ["AUT Master of Law graduation gown set - Front view", "AUT Master of Law graduation gown set - Side view", "AUT Master of Law graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Master of Law",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Master of Law",
    "hood-style" : "Black, lined with Forest Green",
    "colour" : "Fully Lined with Forest Green",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20113",
    "tassel" : "TAS00001",
    "productType" : "aut-master-full-set",
    "colors" : ["#013220"]
  },
  "/aut/master-of-business" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592530430/Uni%20Regalia%20Photos/AUT/aut-mast-buss-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592530430/Uni%20Regalia%20Photos/AUT/aut-mast-buss-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592530430/Uni%20Regalia%20Photos/AUT/aut-mast-buss-back.jpg"],
    "metaTag" : "Buy your AUT Master of Business graduation gown set from Gowning Street!",
    "altTags" : ["AUT Master of Business graduation gown set - Front view", "AUT Master of Business graduation gown set - Side view", "AUT Master of Business graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Master of Business",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Master of Business",
    "hood-style" : "Black, lined with Green",
    "colour" : "Fully Lined with Green",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20114",
    "tassel" : "TAS00001",
    "productType" : "aut-master-full-set",
    "colors" : ["#0B6623"]
  },
  "/aut/master-of-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165128/Uni%20Regalia%20Photos/AUT/aut-mast-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Uni%20Regalia%20Photos/AUT/aut-mast-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Uni%20Regalia%20Photos/AUT/aut-mast-engg-back.jpg"],
    "metaTag" : "Buy your AUT Master of Engineering graduation gown set from Gowning Street!",
    "altTags" : ["AUT Master of Engineering graduation gown set - Front view", "AUT Master of Engineering graduation gown set - Side view", "AUT Master of Engineering graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Master of Engineering",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Master of Engineering",
    "hood-style" : "Black, lined with Yellow",
    "colour" : "Fully Lined with Yellow",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20115",
    "tassel" : "TAS00001",
    "productType" : "aut-master-full-set",
    "colors" : ["yellow"]
  },
  "/aut/master-of-health-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592529777/Uni%20Regalia%20Photos/AUT/aut-mast-healthsci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592529777/Uni%20Regalia%20Photos/AUT/aut-mast-healthsci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592529777/Uni%20Regalia%20Photos/AUT/aut-mast-healthsci-back.jpg"],
    "metaTag" : "Buy your AUT Master of Health Science graduation gown set from Gowning Street!",
    "altTags" : ["AUT Master of Health Science graduation gown set - Front view", "AUT Master of Health Science graduation gown set - Side view", "AUT Master of Health Science graduation gown set - Back view"],
    "name" : "AUT Graduation Gown Set - Master of Health Science",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Auckland University of Technology Hood - Master of Health Science",
    "hood-style" : "Black, lined with Blue",
    "colour" : "Fully Lined with Blue",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOZ20117",
    "tassel" : "TAS00001",
    "productType" : "aut-master-full-set",
    "colors" : ["blue"]
  },

  //Bachelor cap and gown set, no hood

  "/regalia/bachelor-gown-set" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-back.jpg"],
    "metaTag" : "Buy your University bachelor style graduation gown and hat set from Gowning Street!",
    "altTags" : ["University Bachelor hat and gown set - Front view", "University Bachelor hat and gown set - Side view", "University Bachelor hat and gown set - Back view"],
    "name" : "Graduation Gown Set - Bachelor Style",
    "price" : BACHELOR_GOWN_CAP_PRICE,
    "show" : ["gown", "cap"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-gown-and-cap"
    },

  //master cap and gown set, no hood

  "/regalia/master-gown-set" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/mast-cap-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/mast-cap-gown-back.jpg"],
    "metaTag" : "Buy your University master style graduation gown and hat set from Gowning Street!",
    "altTags" : ["University Master hat and gown set - Front view", "University Master hat and gown set - Side view", "University Master hat and gown set - Back view"],
    "name" : "Graduation Gown Set - Master Style",
    "price" : MASTER_GOWN_CAP_PRICE,
    "show" : ["gown", "cap"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : null,
    "productType" : "master-gown-and-cap"
    },

  //Bachelor gown only
  "/regalia/bachelor-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
    "metaTag" : "Buy your University bachelor style graduation gown from Gowning Street!",
    "altTags" : ["University Bachelor gown set - Front view", "University Bachelor gown set - Side view", "University Bachelor gown set - Back view"],
    "name" : "Graduation Gown - Bachelor Style",
    "price" : BACHELOR_GOWN_PRICE,
    "show" : ["gown"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-gown"
  },

  //master gown only
  "/regalia/master-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/mast-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/mast-gown-back.jpg"],
    "metaTag" : "Buy your master style graduation gown from Gowning Street!",
    "altTags" : ["Master gown set - Front view", "Master gown set - Side view", "Master gown set - Back view"],
    "name" : "Graduation Gown - Master Style",
    "price" : MASTER_GOWN_PRICE,
    "show" : ["gown"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Master",
    "hood-code" : null,
    "productType" : "master-gown"
  },

  //Cap only
  "/regalia/graduation-hat" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1557666354/Regalia/graduation-cap-2.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-3.jpg"],
    "metaTag" : "Buy your graduation hat from Gowning Street! Get any size of Graduation Hat at the very best prices. Browse graduation hat for school or university graduation.",
    "titleTag" : "Graduation Hat | Gowning Street Graduation Regalia in New Zealand",
    "altTags" : ["Graduation Hat - Front view", "Graduation Hat - Side view", "Graduation Hat - Back view"],
    "name" : "Graduation Hat",
    "price" : CAP_PRICE,
    "show" : ["cap"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-cap"
  },


  //Gifts
  //!! Remember to update cart-items.js if the below are ever updated
  "/regalia/graduation-teddy-bear" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Regalia/graduation-teddy-bear-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-3.jpg"],
    "metaTag" : "Add a graduation teddy bear to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
    "titleTag" : "Graduation Teddy Bear | Gowning Street Graduation Gifts in New Zealand",
    "altTags" : ["Graduation Teddy Bear - Front view", "Graduation Teddy Bear - Side view", "Graduation Teddy Bear - Back view"],
    "name" : "Graduation Teddy Bear",
    "price" : TEDDY_SMALL_PRICE,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "TED00001",
    "productType" : "gift",
    "giftDescription" : "The perfect gift to congratulate that special someone on their  graduation day. Soft, cuddly and smart, this teddy bear is guaranteed to make graduation day unforgettable!"
  },

  "/regalia/graduation-puppy-large" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-smooth-3.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-puppy-smooth-2.jpg"],
    "metaTag" : "Add a graduation puppy to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
    "titleTag" : "Graduation Puppy - Large | Gowning Street Graduation Gifts in New Zealand",
    "altTags" : ["Graduation Puppy Large - Front view", "Graduation Puppy Large - Side view", "Graduation Puppy Large - Back view"],
    "name" : "Graduation Puppy - Large",
    "price" : PUPPY_LARGE_PRICE,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "PUP00001",
    "productType" : "gift",
    "giftDescription" : "The perfect gift to congratulate that special someone on their  graduation day. Soft, cuddly and smart, this puppy is guaranteed to make graduation day unforgettable!"
  },

  "/regalia/graduation-puppy-small" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Regalia/graduation-puppy-furry-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Regalia/graduation-puppy-furry-3.jpg"],
    "metaTag" : "Add a graduation puppy to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
    "titleTag" : "Graduation Puppy | Gowning Street Graduation Gifts in New Zealand",
    "altTags" : ["Graduation Puppy Small - Front view", "Graduation Puppy Small - Side view", "Graduation Puppy Small - Back view"],
    "name" : "Graduation Puppy - Small",
    "price" : PUPPY_SMALL_PRICE,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "PUP00002",
    "productType" : "gift",
    "giftDescription" : "The perfect gift to congratulate that special someone on their  graduation day. Soft, cuddly and smart, this puppy is guaranteed to make graduation day unforgettable!"
  },

  "/legal-wear/legal-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
    "metaTag" : "Our legal gowns are great for the New Zealand climate offering a breathable polyester weave design. Our legal gown is fully fluted back with a stiffened yolk. Our legal robes are suitable for New Zealand and other Commonwealth jurisdictions",
    "titleTag" : "Legal Gown for New Zealand and other Commonwealth Jurisdictions | Order online from $199",
    "altTags" : ["Legal Gown - Front","Legal Gown - Side","Legal Gown - Back"],
    "name" : "Legal Gown",
    "price" : BARRISTER_GOWN_PRICE,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "GOWL0001",
    "productType" : "gift",
    "giftDescription" : "Legal gown for admission or moving council. Made to perfection from premium quality polyester. Suitable for use by Barristers in courtrooms around New Zealand."
  },

  "/legal-wear/district-court-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
    "metaTag" : "Our district court gowns are great for the New Zealand climate offering a breathable polyester weave design. Our district court gown is fully fluted back with a stiffened yolk. Our district court robes are suitable for New Zealand and other Commonwealth jurisdictions",
    "titleTag" : "District Court Gown for New Zealand and other Commonwealth Jurisdictions | Order online from $199",
    "altTags" : ["District Court Gown - Front","District Court Gown - Side","District Court Gown - Back"],
    "name" : "District Court Gown",
    "price" : ADMISSIONS_GOWN_PRICE,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "GOWL0002",
    "productType" : "gift",
    "giftDescription" : "Uni-Sex Magistrate gowns are made of premium quality polyester weave. Suitable for New Zealand district courts."
  },

  "/legal-wear/legal-wig" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png"],
    "metaTag" : "Gowning Street legal wigs delicately crafted from 100% pure horsehair, made for comfort",
    "titleTag" : "Legal wig for New Zealand and other Commonwealth Jurisdictions | Order online from $199",
    "altTags" : ["Legal Wig - Front","Legal Wig - Side","Legal Wig - Back"],
    "name" : "Legal Wig",
    "price" : BARRISTER_WIG_PRICE,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "WIG00001",
    "productType" : "gift",
    "giftDescription" : "Barrister's wig delicately hand-crafted from 100% pure horsehair, finely stitched for comfort"
  },

};

module.exports = Products;