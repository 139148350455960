import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import Products from '../../sources/products';
import checkInventory  from '../cart/checkInventory';
import Gown from './gown';
import Cap from './cap';
import Hood from './hood';
import Qty from './qty';
import Popup from './popup';
import CustomButton from '../custom-items/button-loader';

import { connect } from 'react-redux';

class Items extends Component {
  constructor(props) {
    super(props);
    const path = props.path;
    const level = Products[path].level;
    const phdStyle = Products[path].phdStyle;
    const hatType = Products[path].hatType;
    let gown = "GOW00003";
    let cap = "HAT00003";

    if (level === "Bachelor") {
      gown = "GOW00003";
    }
    else if (level === "Master"){
      gown = "GOW00013";
    }
    else if (level === "PhD"){
      switch(phdStyle){
        case "cloth":
          gown = "GOW00023";
        break;

        default:
          gown = "GOW00033";
      }

      //Changes hat type depending on Uni's PhD style
      switch(hatType){
        case "red":
          cap = "HAT00023";
          break;

        case "yellow":
          cap = "HAT00033";
          break;

        default:
          cap = "HAT00003";
      }
    }

    let hood = Products[path]["hood-code"];

    const toShow = Products[path].show;

    let gift = Products[path]["hood-code"];

    if(toShow.indexOf("gown") === -1){
      gown = null;
    }

    if(toShow.indexOf("cap") === -1){
      cap = null;
    }

    if(toShow.indexOf("hood") === -1){
      hood = null;
    }

    if(toShow.indexOf("gift") === -1){
      gift = null;
    }

    if (Products[path]["tassel"]) {
      gift = Products[path]["tassel"]
    }

    var image = Products[path].photos[0];
    this.state = {
      gown : gown,
      cap : cap,
      hood : hood,
      gift : gift,
      qty : 1,
      popup : false,
      xhrLoaded : false,
      error : null
    };

    this.handleClick = this.handleClick.bind(this);
    this.setGown = this.setGown.bind(this);
    this.setCap = this.setCap.bind(this);
    this.setQty = this.setQty.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  componentWillReceiveProps() {
    var path = window.location.pathname;
    var level = Products[path].level;
    var phdStyle = Products[path].phdStyle;
    var hatType = Products[path].hatType;
    var gown = "GOW00003";
    var cap = "HAT00003";

    if (level === "Bachelor") {
      gown = "GOW00003";
    }
    else if (level === "Master"){
      gown = "GOW00013";
    }
    else if (level === "PhD"){
      switch(phdStyle){
        case "cloth":
          gown = "GOW00023";
        break;

        default:
          gown = "GOW00033";
      }

      //Changes hat type depending on Uni's PhD style
      switch(hatType){
        case "red":
          cap = "HAT00023";
          break;

        case "yellow":
          cap = "HAT00033";
          break;

        default:
          cap = "HAT00003";
      }
    }

    var toShow = Products[path].show;

    let gift = Products[path]["hood-code"];

    if(toShow.indexOf("gift") === -1){
      gift = null;
    }

    if(toShow.indexOf("gown") === -1){
      gown = null;
    }

    if(toShow.indexOf("cap") === -1){
      cap = null;
    }

    if (Products[path]["tassel"]) {
      gift = Products[path]["tassel"]
    }

    this.setState({
      gown : gown,
      cap : cap,
      gift : gift,
      error : null
    });
  }


  setGown(code){
    this.setState({
      gown : code
    });
  }

  setCap(code){
    this.setState({
      cap : code
    });
  }

  setQty(qty){
    this.setState({
      qty : qty
    });
  }

  handleClick(name, hoodCode, hoodStyle, price, productType, image){
    const context = this;
    const { props, state} = this;
    const toShow = Products[window.location.pathname].show;

    const items = {
      gown: state.gown,
      cap: state.cap,
      hood: hoodCode,
      gift: state.gift,
    };

    ReactGA.event('add_to_cart', {
      category: 'add_to_cart',
      action: 'item added to cart',
      label: 'item added to cart',
      items: toShow.map(itemName => ({
        item_id: items[itemName],
        item_category: productType,
        quantity: this.state.qty,
        price,
      }))
    });

    const toCheck = toShow.map((itemName) => {
      return ({
        code: items[itemName],
        qty: state.qty,
      })
    })

    let hasFailed = [];

    checkInventory(toCheck, function(fails){
      hasFailed = fails;

      if(hasFailed.length === 0){
        props.onClick(
          name,
          state.gown,
          state.cap,
          hoodCode,
          state.gift,
          hoodStyle,
          state.qty,
          price,
          productType,
          image
        );

        context.setState({
          popup : true,
          xhrLoaded : true
        });
      }
      else {
        context.setState({
          error : fails,
          xhrLoaded : true
        });
      }

    });

  }

  hidePopup(){
    this.setState({
      popup : false
    });
  }

  renderSelections(product, setGown, setCap) {
    const { hood, hoodStyle, level, phdStyle, hatType } = product;
    let selections = product.show.map(function(element){
      switch(element){
        case "gown":
          return <Gown
            key={element}
            setGown={setGown}
            level={level}
            phdStyle={phdStyle}
          />
          break;
        case "hood":
          return <Hood key={element} hood={hood} hoodStyle={hoodStyle} />
          break;
        case "cap":
          return <Cap
            key={element}
            setCap={setCap}
            level={level}
            hatType={hatType}
          />
          break;
      }
    });
    return selections;
  }

  render() {
    const path = window.location.pathname;
    const product = Products[path]
    let name = product.name;
    let hood = product.hood;
    let hoodStyle = product["hood-style"];
    let hoodCode = product["hood-code"];
    let productType = product.productType;
    let price = product.price;
    let image = product.photos[0];
    let initialQty = 1;

    if (Products[path].show.indexOf("hood") === -1){
      hoodCode = null;
    }

    let errors = null;

    if(this.state.error){
      errors = this.state.error.map(function(error){
        if (error.maxQty === 0) {
          return (
            <p key={error.code}>
              Sorry, we are currently out of stock of {error.description}.
            </p>
          )
        }
        else {
          return (
            <p key={error.code}>
              Sorry, we only have {error.maxQty} of {error.description}. Please choose a lower amount.
            </p>
          )
        }
      });
    }

    return (
      <div className="product-selections">
        { this.renderSelections(product, this.setGown, this.setCap) }
        <div className="quantity">
          <h3>Qty</h3>
          <Qty initialQty={initialQty} setQty={this.setQty}/>
        </div>
        <div className="errors">
          {errors}
        </div>
        <CustomButton
          handleSubmit={this.handleClick.bind(this, name, hoodCode, hoodStyle, price, productType, image, checkInventory)}
          content="Add to Cart"
          xhrLoaded={this.state.xhrLoaded}
        />
        {this.state.popup === true ?
          <Popup
            price={price}
            qty={this.state.qty}
            hidePopup={this.hidePopup}
            name={name}
          /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    onClick: (name, gown, cap, hood, gift, hoodStyle, qty, price, productType, image) => {

      dispatch({
        type: "ADD_TO_CART",
        name: name,
        gown: gown,
        cap: cap,
        hood: hood,
        gift: gift,
        hoodStyle: hoodStyle,
        qty: qty,
        price: price,
        productType : productType,
        image : image
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Items);
