import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const FAQ = () => (
  <div className="FAQ">
    <Helmet
      title="Gowning Street NZ FAQ"
      meta={[
        {"name": "description", "content": "Gowning Street New Zealand frequently asked questions"},
      ]}
    />
    <div className="FAQ-contents">
      <h1>Frequently Asked Questions</h1>
      <h3>Why are your regalia prices so little compared to Universities and other Private Companies?</h3>
        <p>As we are online only, hence, we do not pay store rent or employ large amounts of staff. We use technology to automate as many of our processes to keep overheads low. As we run the process from manufacturing through to selling our gowns online, we cut out the middle-man, ensuring we keep costs low. This means all of these savings are passed down to you, the consumer without any compromise on the quality of our gowns. </p>
      <h3>How long will delivery take?</h3>
        <p>If you order before 12pm, we offer same-day dispatch. Generally, shipping takes 1-2 days (North Island) and 3-4 days (South Island).</p>
        <p>Depending on your location, for shipping estimates please check our <Link to="/shipping">Shipping Estimates Page</Link></p>
      <h3>What is your returns/exchange policy?</h3>
        <p>Where items are found to be damaged or defective  we will offer a full refund. In this case, claims must be made within 7 days of delivery as recorded by New Zealand Couriers</p>
        <p>For returns based on incorrect sizing or change of mind we can only refund the product cost and not the shipping cost. Returns must have products in their original packaging and in full saleable condition to be accepted. Requests must be made within 7 days. A handling fee will apply on exchanges.</p>
      <h3>Which payment options to do you provide?</h3>
        <p>We accept payments via Paypal or via Credit/Debit card through Stripe.</p>
      <h3>Do your gowns and hats comply with those rented out by my university?</h3>
        <p>Yes, our gowns and hats are manufactured in strict accordance to University Academic dress guidelines. We ensure that every item we sell complies with the requisite university academic dress statute. If wanting further information, please refer to your University graduation dress guidelines on your University website.</p>
      <h3>How should I treat my items once I receive them?</h3>
        <p>We recommend you hang your academic gown after receiving it to allow any creases to fall out naturally. Your Graduation Gowns can also be ironed at low heat to remove any remaining creases. Furthermore, your graduation hat should be unpacked once received to remove any creases. If needing to clean your gown, we recommend you  dry clean only.</p>
      <h3>Do you have discounts for Bulk Orders? </h3>
        <p>You can receive our student or wholesale discount when you <Link to="/save-with-friends">buy in bulk</Link>. For bulk orders of 25 or more gown sets, please <Link to="/contact">Contact Us</Link></p>
    </div>
  </div>
)

export default FAQ;
