  const gownCap = {
  "GOW00001" : "Bachelor Style Gown - XS",
  "GOW00002" : "Bachelor Style Gown - S",
  "GOW00003" : "Bachelor Style Gown - M",
  "GOW00004" : "Bachelor Style Gown - L",
  "GOW00005" : "Bachelor Style Gown - XL",
  "GOW00011" : "Masters Style Gown - XS",
  "GOW00012" : "Masters Style Gown - S",
  "GOW00013" : "Masters Style Gown - M",
  "GOW00014" : "Masters Style Gown - L",
  "GOW00015" : "Masters Style Gown - XL",
  "GOW00021" : "PhD Style Gown - XS",
  "GOW00022" : "PhD Style Gown - S",
  "GOW00023" : "PhD Style Gown - M",
  "GOW00024" : "PhD Style Gown - L",
  "GOW00025" : "PhD Style Gown - XL",
  "GOW00031" : "PhD Style Gown - XS",
  "GOW00032" : "PhD Style Gown - S",
  "GOW00033" : "PhD Style Gown - M",
  "GOW00034" : "PhD Style Gown - L",
  "GOW00035" : "PhD Style Gown - XL",
  "HAT00001" : "Hat - XS",
  "HAT00002" : "Hat - S",
  "HAT00003" : "Hat - M",
  "HAT00004" : "Hat - L",
  "HAT00005" : "Hat - XL",
  "HAT00021" : "Hat - XS",
  "HAT00022" : "Hat - S",
  "HAT00023" : "Hat - M",
  "HAT00024" : "Hat - L",
  "HAT00025" : "Hat - XL",
  "HAT00031" : "Hat - XS",
  "HAT00032" : "Hat - S",
  "HAT00033" : "Hat - M",
  "HAT00034" : "Hat - L",
  "HAT00035" : "Hat - XL",
};

module.exports = { gownCap }