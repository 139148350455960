import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import { connect } from 'react-redux';
import Drawer from 'material-ui/Drawer';
import DrawerContent from './homepage/drawercontent';

import Menu from './Menu';

const Header = ({
  order,
  customer,
  dropCart,
  dropCustomer,
  children,
}) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [itemsInCart, setItemsInCart] = useState(order.length);

  useEffect(() => {
    const checkPrice = async () => {
      const result = await axios.post('/api/checkPrice', {
        items: order,
        customer: customer
      })
      return result;
    };

    if (order.length > 0) {
      try {
        checkPrice().then((checkPriceResult) => {
          if (!checkPriceResult.data.success) {
            dropCart();
            dropCustomer();
            history.push('/');
          }
        })
      } catch (error) {
        history.push('/');
      }
    }
  }, []);

  useEffect(() => {
    let itemsCount = 0;
    order.forEach(function (element) {
      itemsCount += element.qty;
    });
    setItemsInCart(itemsCount)
  }, [order]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (route) => {
    setOpen(false);
    history.push(route);
  };

  const navigate = (route) => {
    history.push(route);
  }

  return (
    <div className="header">

      <div className="banner">
        <div id="flags">
          <a target="blank" href="https://www.gowningstreet.com.au"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/Australia_Flag.png"/></a>
          <a><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/New_Zealand_Flag.png"/></a>
          <a href="https://www.gowningstreet.co.uk"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/United_Kingdom_Flag.png"/></a>
        </div>
        <p>We Are Open! $11 Flat Rate Shipping</p>
      </div>

      <div className="headertop">
        <img
          onClick={handleToggle}
          src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/menu_grey_24x24.png"
          className="nav-icon"
        />
        <Link to="/">
          <img
            src="https://res.cloudinary.com/gowningstreet/image/upload/v1550922414/Utility/Gowningstreet_logo.jpg"
            className="logo"
          />
        </Link>
        <Link className="cart-and-items" to="/cart">
            <img
              src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/shopping_cart_grey_24x24.png"
              className="cart-icon"
            />
            <p className="cart-items">
              {itemsInCart}
            </p>
        </Link>
      </div>
      <Menu />
      <Drawer
        docked={false}
        width={280}
        open={open}
        className="drawer"
        onRequestChange={(open) => setOpen(!open)}
      >
        <DrawerContent
          close={handleClose}
        />
      </Drawer>
      {children}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    },
    dropCart: () => {
      dispatch({
        type: 'EMPTY_CART'
      })
    },
    dropCustomer: () => {
      dispatch({
        type: 'DROP_CUSTOMER'
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
