import React, { Component } from 'react';

class Cap extends Component {
  constructor(props) {
    super(props);
    const path = window.location.pathname;
    const level = props.level;
    const hatType = props.hatType;
    let selections;

    if(level === "Bachelor" || level === "Master") {
      selections = ["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"];
    }

    if(level === "PhD") {
      switch(hatType){
        case "bachelor":
          selections = ["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"];
          break;

        case "red":
          selections = ["HAT00021", "HAT00022", "HAT00023", "HAT00024", "HAT00025"];
          break;

        case "yellow":
          selections = ["HAT00031", "HAT00032", "HAT00033", "HAT00034", "HAT00035"];
          break;
      }

    }

    this.state = {
      selections: selections,
      selectedValue: selections[2],
    };
  }

  handleChange(event) {
    this.setState({
      selectedValue: event.target.value,
    });

    this.props.setCap(event.target.value);
  }

  render() {
    return (
      <div className="cap-size">
        <h3>Choose your Hat size</h3>
        <p className="sizedesc">
          Based on head circumference at the widest point. Click <a href="https://res.cloudinary.com/gowningstreet/image/upload/v1549425049/Utility/Gowning-Street-Printable-Measurement-Ruler.pdf" target="_blank">here</a> for a printable ruler
        </p>
        <select
          className="select-size"
          value={this.state.selectedValue}
          onChange={this.handleChange.bind(this)}
        >
          <option value={this.state.selections[0]}>Extra Small (Less than 47cm)</option>
          <option value={this.state.selections[1]}>Small (48 - 51cms)</option>
          <option value={this.state.selections[2]}>Medium (52 - 54cms)</option>
          <option value={this.state.selections[3]}>Large (55 - 59cms)</option>
          <option value={this.state.selections[4]}>Extra Large (More than 59cms)</option>
        </select>
      </div>
    );
  }
}

export default Cap;
