import React from 'react';

const ShippingTable = () => (
  <div className="shipping-table">
    <p className="label">Number of business days:</p>
    <table>
      <thead>
        <tr>
          <th>Destination</th>
          <th>Standard</th>
          <th>Express</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>North Island</td>
          <td>Up to 2 business days</td>
          <td>1 business day</td>
        </tr>
        <tr>
          <td>South Island</td>
          <td>Up to 4 business days</td>
          <td>Up to 2 business days</td>
        </tr>
        <tr>
          <td>Note: Rural Deliveries</td>
          <td>May take an extra 2-3 business days</td>
          <td>May take an extra business day</td>
        </tr>
      </tbody>
    </table>
    <div className="description">
      <p>Please also allow 1 business day for dispatch from our warehouse, particularly for orders placed after 12 noon. Times shown above are in addition to dispatch times.</p>
      <p>Please note that these are the delivery times as suggested by NZ Couriers, and may vary during peak periods. We strongly suggest leaving a buffer prior to your graduation. Gowning Street cannot guarantee the above times and will not be held responsible for delays.</p>
      <p>
        For more information on post delivery estimates please visit the
        <a href="https://www.nzcouriers.co.nz/schedules.html" target="_blank"> NZ Couriers website</a>
      </p>
      <p>For international shipping details, as they are country-dependent, please check DHL website.</p>
    </div>
  </div>
)

export default ShippingTable;