import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { useHistory, Link } from "react-router-dom";
import { connect } from 'react-redux';

import { Frames } from '../../sources/frames';
const FrameSpecs = require('../../sources/frameOptions');
import Qty from '../selections/qty';

import FrameSelection from '../selections/frameSelection';
import FrameDescription from '../selections/frameDescription';

import checkInventory from '../cart/checkInventory';
import Popup from '../selections/popup';
import { MODERN_FRAME, TRADITIONAL_FRAME } from '../../../constants';

class FrameParent extends Component {
  constructor(props) {
    super(props);
    var path = window.location.pathname
    var altTag = "Graduation Certificate Frame Display Image";
    var image = 'https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg'

    var frameSize = Frames[path].frameSize;
    //eg. A4

    var matteStyle = Frames[path].matteStyle;
    //eg. F1D1

    var frameStyle = FrameSpecs.frames[frameSize][0].barcode;
    //eg. FRA00001

    var frameColour = FrameSpecs.mattes[matteStyle][0].barcode;
    //eg. MAT00001

    if (Frames[path].photos) {
      altTag = Frames[path].altTags[5];
      const photo = Frames[path].photos[5]
      image = photo.url
      frameStyle = photo.style
    }


    this.state = {
      image: image,
      mainAlt: altTag,
      path: path,
      frameStyle: frameStyle,
      frameColour: frameColour,
      frameSize: frameSize,
      qty: 1,
      price: MODERN_FRAME,
      popup : false,
      name: Frames[path].name,
      popupname: Frames[path].name,
      error: false,
      frameUrl: null
    }

    this.setPrimary = this.setPrimary.bind(this);
    this.setQty = this.setQty.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.hidePopup = this.hidePopup.bind(this);

  }

  componentDidUpdate() {
    let currentPath = window.location.pathname

    var frameSize = Frames[currentPath].frameSize;
    var matteStyle = Frames[currentPath].matteStyle;
    var frameStyle = FrameSpecs.frames[frameSize][0].barcode;
    var frameColour = FrameSpecs.mattes[matteStyle][0].barcode;

    if (currentPath !== this.state.path) {

      this.setState({
        path: currentPath,
        name: Frames[currentPath].name,
        frameSize: frameSize,
        frameStyle: frameStyle,
        frameColour: frameColour,
        price: MODERN_FRAME,
        error: false
      })
    }

  }

  setPrimary(event) {
    this.setState({
      image: event.target.id,
      mainAlt: event.target.alt
    });
  }

  setQty(qty) {
    this.setState({
      qty: qty
    })
  }

  setSelection(type, value) {
    this.setState({ [type]: value })

    if (type === 'frameStyle') {
      if (value === 'FRA00002' || value === 'FRA00012') {
        this.setState({ price: TRADITIONAL_FRAME })
      } else {
        this.setState({ price: MODERN_FRAME })
      }
    }
  }

  handleClick() {
    const frameNames = {
      "FRA00001": " - Modern",
      "FRA00002": " - Pine Wood",
      "FRA00011": " - Modern",
      "FRA00012": " - Pine Wood",
      "FRA00003": " - Modern",
      "FRA10003": " - Pine Wood",
    }

    const { frameStyle, frameColour, qty, price, name } = this.state;
    const toCheck = [];
    toCheck.push({ code: frameStyle, qty });
    toCheck.push({ code: frameColour, qty });

    const that = this;
    checkInventory(toCheck, function (fails) {
      if (fails.length === 0) {
        var name = that.state.name + frameNames[that.state.frameStyle]
        const imagePath = that.state?.frameUrl ? that.state.frameUrl.getAttribute('src') : null

        that.props.addToCart(name, null, null, frameStyle, frameColour, null, qty, price, "frame", imagePath)
        that.setState({
          popup: true,
          popupname: name,
          error: false
        })
      }
      else {
        that.setState({
          error: fails,
        })
      }
    })


  }

  hidePopup() {
    this.setState({
      error: false,
      popup: false
    })
  }

  renderImage(frameObj) {
    let foundIndex = 0;

    const photo = frameObj.photos.find((photo, index) => {
      if (photo.style === this.state.frameStyle && photo.colour === this.state.frameColour) {
        foundIndex = index
        return true
      }
    })
    const alt = frameObj.altTags[foundIndex]

    return (
      <div>
        <img
          src={photo.url}
          alt={alt}
          id="frame-main"
          ref={(img) => { this.state.frameUrl = img }}
        />
      </div>
    )
  }

  toCart() {
    let history = useHistory();
    history.push("/cart");
  }

  render() {
    var that = this;
    var path = this.state.path;

    var images = Frames[path].photos;
    var altTags = Frames[path].altTags;
    const uniShortName = Frames[path].name.split('Certificate')[0]

    var frameSize = Frames[path].frameSize;
    //eg. A4

    var matteStyle = Frames[path].matteStyle;
    //eg. F1D1

    var frameStyleOptions = FrameSpecs.frames[frameSize];
    var matteColourOptions = FrameSpecs.mattes[matteStyle];

    var display = images.map(function (element, index) {

      if (!altTags) {
        return (
          <img
            src={element}
            alt="Graduation Certificate Frames Display Image"
            className="image-mini"
            key={`${element}-${index}`}
            id={element}
            onClick={that.setPrimary}
          />
        )
      }
      else {
        return (
          <img
            src={element}
            alt={altTags[index]}
            className="image-mini"
            key={`${element}-${index}`}
            id={element}
            onClick={that.setPrimary}
          />
        )
      }

    })
    let errors = ''
    if (this.state.error) {
      errors = this.state.error.map(function (error) {
        if (error.maxQty === 0) {
          return (
            <p key={error.code}>
              Sorry, we are currently out of stock of {error.description}.
            </p>
          )
        }
        else {
          return (
            <p key={error.code}>
              Sorry, we only have {error.maxQty} of {error.description}. Please choose a lower amount.
            </p>
          )
        }
      });
    }

    var frameObj = Frames[path]

    var isFrames = false;

    if (window.location.pathname.indexOf("frames") !== -1) {
      isFrames = true;
    }

    var titleTag = Frames[path].name + " | " + "University Certificate Frames";

    if (isFrames) {
      titleTag = "Gowning Street Certificate Frames";
    }

    if (Frames[path].titleTag) {
      titleTag = Frames[path].titleTag;
    }

    var metaTag = "Gowning Street provides quality, hand crafted graduation gown sets for New Zealand University students";

    if (Frames[path].metaTag) {
      metaTag = Frames[path].metaTag;
    }

    return (
      <div className="product-set">
        <Helmet
          title={titleTag}
          meta={[
            { "name": "description", "content": metaTag },
          ]}
        />
        <div className="frame-selection-container">
          <div className="image-set-noflex">
            <h1>{Frames[path].name}</h1>
            <h2 className="price">${this.state.price}</h2>
            {this.renderImage(frameObj)}
          </div>
          <div>
            <FrameSelection
              title="Frame style:"
              options={frameStyleOptions}
              type="frameStyle"
              setSelection={this.setSelection}
              chosen={this.state.frameStyle}
            />
            <FrameSelection
              title="Matte color:"
              options={matteColourOptions}
              type="frameColour"
              setSelection={this.setSelection}
              chosen={this.state.frameColour}
            />
            <div className="qty-display">
              <p>Qty: </p>
              <Qty setQty={this.setQty} />
            </div>
            <div className="btn-group">
              <button onClick={this.handleClick} type="button" >Add to Cart</button>
            </div>
            <div className="errors">
              {errors}
            </div>
            {this.state.popup === true ?
              <Popup
                price={this.state.price}
                qty={this.state.qty}
                hidePopup={this.hidePopup}
                name={this.state.popupname}
              /> : null}
          </div>
        </div>
        <div className="description-container">
          <FrameDescription path={path} uniShortName={uniShortName} />
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteItem: (id) => {
      dispatch({
        type: 'REMOVE_FROM_CART',
        id: id
      })
    },
    addToCart: (name, gown, cap, hood, gift, hoodStyle, qty, price, productType, image) => {
      dispatch({
        type: "ADD_TO_CART",
        name: name,
        gown: gown,
        cap: cap,
        hood: hood,
        gift: gift,
        hoodStyle: hoodStyle,
        qty: qty,
        price: price,
        productType: productType,
        image: image,
      })
    }
  }
}

const UniFrames = connect(
  mapStateToProps,
  mapDispatchToProps
)(FrameParent);

export default UniFrames;
