var Universities = {
  "/waikato-uni" : {
    "name" : "Waikato Uni Graduation Gown Sets",
    "searchName" : "Waikato | University of Waikato",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1549165123/Uni%20Campus%20Photos/Waikato/Waikato_LS_2.jpg",
    "altTag" : "University of Waikato Lake View Photo",
    "abbreviation" : "Waikato",
    "titleTag" : "Waikato Uni graduation gown and academic dress | From $89",
    "metaTag" : "Looking to graduate? Why hire your graduation gown, when you can purchase it for less and keep it for a lifetime? Gowning Street stocks premium quality University of Waikato graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor Degrees",
        "path" : "/waikato-uni/bachelor-degrees",
        "colors" : ["#F8B400"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor Degrees (Honours)",
        "path" : "/waikato-uni/bachelor-degrees-honours",
        "colors" : ["#F8B400"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Masters Degrees",
        "path" : "/waikato-uni/master-degrees",
        "colors" : ["#F8B400"]
      }
    ],
    "graduations-ref" : "https://www.waikato.ac.nz/students/graduation/ceremonies",
    "graduations" : [
      {
        "Date" : "Friday - 3 May",
        "Subjects" : [
          "TBD",
        ]
      },
      {
        "Date" : "Tuesday - 7 May",
        "Subjects" : [
          "TBD"
        ]
      },
    ]
  },

  "/wintec" : {
    "name" : "Wintec Graduation Gown Sets",
    "searchName" : "Wintec | Waikato Institute of Technology",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1549165123/Uni%20Campus%20Photos/Waikato/Waikato_LS_2.jpg",
    "altTag" : "Wintec Lake View Photo",
    "abbreviation" : "Wintec",
    "titleTag" : "Wintec graduation gown and academic dress | From $89",
    "metaTag" : "Graduating from Wintec? Why hire your graduation gown, when you can purchase a gown and hat set for $89? Gowning Street stocks premium quality Wintec graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor Degrees",
        "path" : "/wintec/bachelor-degrees",
        "colors" : ["blue"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      }
    ],
    "graduations-ref" : "https://www.wintec.ac.nz/student-resources/graduation",
    "graduations" : [
      {
        "Date" : "Friday - 3 May",
        "Subjects" : [
          "TBD",
        ]
      },
      {
        "Date" : "Tuesday - 7 May",
        "Subjects" : [
          "TBD"
        ]
      },
    ]
  },

  "/auckland-uni" : {
    "name" : "Auckland Uni Graduation Gown Sets",
    "searchName" : "Auckland Uni",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag" : "Auckland University Photo",
    "abbreviation" : "Auckland Uni",
    "titleTag" : "Auckland Uni graduation gown and academic dress | from $89",
    "metaTag" : "Why Hire? When you can buy your Auckland Uni graduation gown for $89. Fast Delivery. Easy Exchange. Premium Quality",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Arts",
        "path" : "/auckland-uni/bachelor-of-arts",
        "colors" : ["pink"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Commerce",
        "path" : "/auckland-uni/bachelor-of-commerce",
        "colors" : ["orange"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Architectural Studies",
        "path" : "/auckland-uni/bachelor-of-architectural-studies",
        "colors" : ["#FDFDFB"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Education",
        "path" : "/auckland-uni/bachelor-of-education",
        "colors" : ["green"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Engineering",
        "path" : "/auckland-uni/bachelor-of-engineering",
        "colors" : ["purple"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Law",
        "path" : "/auckland-uni/bachelor-of-law",
        "colors" : ["lightblue"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Health Sciences",
        "path" : "/auckland-uni/bachelor-of-health-sciences",
        "colors" : ["#c8a2c8"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Nursing",
        "path" : "/auckland-uni/bachelor-of-nursing",
        "colors" : ["#000080"]
      },
      {
        "level" : "Bachelor",
        "name" : "Bachelor of Science",
        "path" : "/auckland-uni/bachelor-of-science",
        "colors" : ["#001a33"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Master of Arts",
        "path" : "/auckland-uni/master-of-arts",
        "colors" : ["pink"]
      },
      {
        "level" : "Master",
        "name" : "Master of Commerce",
        "path" : "/auckland-uni/master-of-commerce",
        "colors" : ["orange"]
      },
      {
        "level" : "Master",
        "name" : "Master of Architectural Studies",
        "path" : "/auckland-uni/master-of-architectural-studies",
        "colors" : ["#F8B400"]
      },
      {
        "level" : "Master",
        "name" : "Master of Education",
        "path" : "/auckland-uni/master-of-education",
        "colors" : ["green"]
      },
      {
        "level" : "Master",
        "name" : "Master of Engineering",
        "path" : "/auckland-uni/master-of-engineering",
        "colors" : ["purple"]
      },
      {
        "level" : "Master",
        "name" : "Master of Law",
        "path" : "/auckland-uni/master-of-law",
        "colors" : ["lightblue"]
      },
      {
        "level" : "Master",
        "name" : "Master of Health Sciences",
        "path" : "/auckland-uni/master-of-health-sciences",
        "colors" : ["#c8a2c8"]
      },
      {
        "level" : "Master",
        "name" : "Master of Nursing",
        "path" : "/auckland-uni/master-of-nursing",
        "colors" : ["#000080"]
      },
      {
        "level" : "Master",
        "name" : "Master of Science",
        "path" : "/auckland-uni/master-of-science",
        "colors" : ["#001a33"]
      },
    ],
    "graduations-ref" : "https://www.auckland.ac.nz/en/students/academic-information/graduation/graduation-ceremony-schedule.html",
    "graduations" : [
      {
        "Date" : "September / TBD",
        "Subjects" : [
          "Science",
          "Business & Economics",
          "Arts",
          "Engineering",
          "Law"
        ]
      },
      {
        "Date" : "November / TBD",
        "Subjects" : [
          "Medicine",
          "Medical Sciences"
        ]
      },
      {
        "Date" : "December / TBD",
        "Subjects" : [
          "Health Sciences"
        ]
      }
    ]
  },

  "/aut" : {
    "name" : "AUT Gowns | Auckland University of Technology Gowns",
    "searchName" : "AUT | Auckland University of Technology",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1549165126/Uni%20Campus%20Photos/AUT/AUT_Main_LS.jpg",
    "altTag" : "AUT main courtyard photo",
    "abbreviation" : "AUT",
    "titleTag" : "AUT Graduation Gowns | Auckland University of Technology Gowns | From $89",
    "metaTag" : "Why Hire? When you can buy your AUT graduation gown for $99. Fast Delivery. Easy Exchange. Premium Quality.",
    "degrees" : [
       {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Bachelor",
        "name" : "Arts",
        "path" : "/aut/bachelor-of-arts",
        "colors" : ["white","#DE1738"]
      },
      {
        "level" : "Bachelor",
        "name" : "Business",
        "path" : "/aut/bachelor-of-business",
        "colors" : ["white","green"]
      },
      {
        "level" : "Bachelor",
        "name" : "Education",
        "path" : "/aut/bachelor-of-education",
        "colors" : ["white","#cc00cc"]
      },
      {
        "level" : "Bachelor",
        "name" : "Law",
        "path" : "/aut/bachelor-of-law",
        "colors" : ["white","#00281F"]
      },
      {
        "level" : "Bachelor",
        "name" : "Engineering",
        "path" : "/aut/bachelor-of-engineering",
        "colors" : ["white","#F7E06C"]
      },
      {
        "level" : "Bachelor",
        "name" : "Health Science",
        "path" : "/aut/bachelor-of-health-science",
        "colors" : ["white","blue"]
      },
      {
        "level" : "Bachelor",
        "name" : "Conjoint Degrees",
        "path" : "/aut/bachelor-conjoint-degrees",
        "colors" : ["white","#640E27"]
      },
      {
        "level" : "Bachelor",
        "name" : "Computer & Information Sciences",
        "path" : "/aut/bachelor-of-comp-info-science",
        "colors" : ["white","#d0f0c0"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Education",
        "path" : "/aut/master-of-education",
        "colors" : ["#ff00ff"]
      },
      {
        "level" : "Master",
        "name" : "Arts",
        "path" : "/aut/master-of-arts",
        "colors" : ["#DE1738"]
      },
      {
        "level" : "Master",
        "name" : "Law",
        "path" : "/aut/master-of-law",
        "colors" : ["#013220"]
      },
      {
        "level" : "Master",
        "name" : "Business",
        "path" : "/aut/master-of-business",
        "colors" : ["#0B6623"]
      },
      {
        "level" : "Master",
        "name" : "Engineering",
        "path" : "/aut/master-of-engineering",
        "colors" : ["#F3D74F"]
      },
      {
        "level" : "Master",
        "name" : "Health Science",
        "path" : "/aut/master-of-health-science",
        "colors" : ["#0000FF"]
      }
    ],
    "graduations-ref" : "https://www.aut.ac.nz/student-life/graduation/graduation-dates-and-times",
    "graduations" : [
      {
        "Date" : "Provisional Dates TBD",
        "Subjects" : [
          "All Faculties"
        ]
      }
    ]
  },
  "/victoria-university-of-wellington" : {
    "name" : "Victoria University of Wellington Graduation Gown Sets | VUW Gowns",
    "searchName" : "VUW | Victoria University of Wellington",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag" : "Victoria University of Wellington Photo",
    "abbreviation" : "VUW",
    "titleTag" : "Victoria University of Wellington graduation gown and academic dress | VUW gowns from $89",
    "metaTag" : "Looking to graduate? Why hire your graduation gown, when you can purchase it for less and keep it for a lifetime? Gowning Street stocks premium quality VUW graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Bachelor Gown",
        "path" : "/regalia/bachelor-gown",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Master Gown",
        "path" : "/regalia/master-gown",
        "colors" : ["black"]
      }
    ],
    "graduations-ref" : "https://www.wgtn.ac.nz/students/graduation-and-beyond/graduation/ceremonies/graduation-timeline",
    "graduations" : [
      {
        "Date" : "May 2020",
        "Subjects" : [
          "Ceremonies TBD",
        ]
      },
      {
        "Date" : "TBD",
        "Subjects" : [
          "Ceremonies TBD"
        ]
      },
    ]
  },
  "/canterbury-uni" : {
    "name" : "University of Canterbury Graduation Gown Sets",
    "searchName" : "University of Canterbury | UC",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag" : "University of Canterbury Photo",
    "abbreviation" : "UC",
    "titleTag" : "University of Canterbury graduation gown and academic dress | VUW gowns from $89",
    "metaTag" : "Looking to graduate? Why hire your graduation gown, when you can purchase it for less and keep it for a lifetime? Gowning Street stocks premium quality University of Canterbury graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      }
    ],
    "graduations-ref" : "https://www.canterbury.ac.nz/study/graduation/ceremony-dates/",
    "graduations" : [
      {
        "Date" : "May 2020",
        "Subjects" : [
          "Ceremonies TBD",
        ]
      },
      {
        "Date" : "TBD",
        "Subjects" : [
          "Ceremonies TBD"
        ]
      },
    ]
  },
  "/massey-uni" : {
    "name" : "Massey University of New Zealand Graduation Gown Sets",
    "searchName" : "Massey University of New Zealand",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag" : "Massey University of New Zealand Photo",
    "abbreviation" : "Massey",
    "titleTag" : "Massey University of New Zealand graduation gown and academic dress | Massey Uni gowns from $89",
    "metaTag" : "Looking to graduate? Why hire your graduation gown, when you can purchase it for less and keep it for a lifetime? Gowning Street stocks premium quality Massey University graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      }
    ],
    "graduations-ref" : "https://www.massey.ac.nz/massey/student-life/graduation/graduation.cfm",
    "graduations" : [
      {
        "Date" : "May 2020",
        "Subjects" : [
          "Ceremonies TBD",
        ]
      },
      {
        "Date" : "TBD",
        "Subjects" : [
          "Ceremonies TBD"
        ]
      },
    ]
  },
  "/lincoln-uni" : {
    "name" : "Lincoln University Graduation Gown Sets",
    "searchName" : "Lincoln University",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag" : "Lincoln University Photo",
    "abbreviation" : "Lincoln Uni",
    "titleTag" : "Lincoln University graduation gown and academic dress | Lincoln gowns from $89",
    "metaTag" : "Looking to graduate? Why hire your graduation gown, when you can purchase it for less and keep it for a lifetime? Gowning Street stocks premium quality Lincoln University graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      }
    ],
    "graduations-ref" : "https://www.lincoln.ac.uk/home/studentlife/graduation/",
    "graduations" : [
      {
        "Date" : "May 2020",
        "Subjects" : [
          "Ceremonies TBD",
        ]
      },
      {
        "Date" : "September 2020",
        "Subjects" : [
          "Ceremonies TBD"
        ]
      },
    ]
  },
  "/university-of-otago" : {
    "name" : "University of Otago Graduation Gown Sets",
    "searchName" : "University of Otago",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag" : "Otago University Photo",
    "abbreviation" : "Otago Uni",
    "titleTag" : "University of Otago graduation gown and academic dress | Otago gowns from $89",
    "metaTag" : "Looking to graduate? Why hire your graduation gown, when you can purchase it for less and keep it for a lifetime? Gowning Street stocks premium quality University of Otago graduation gowns.",
    "degrees" : [
      {
        "level" : "Bachelor",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/bachelor-gown-set",
        "colors" : ["black"]
      },
      {
        "level" : "Master",
        "name" : "Gown & Cap Set (Hood not incl.)",
        "path" : "/regalia/master-gown-set",
        "colors" : ["black"]
      }
    ],
    "graduations-ref" : "https://www.otago.ac.nz/study/otago069905.html",
    "graduations" : [
      {
        "Date" : "May 2020",
        "Subjects" : [
          "Ceremonies TBD",
        ]
      },
      {
        "Date" : "September 2020",
        "Subjects" : [
          "Ceremonies TBD"
        ]
      },
    ]
  },
};


module.exports = Universities;