import React from 'react';
import { Link } from 'react-router-dom';
import { NZ_GOWNING_STREET_PHONE_NUMBER } from '../constants';

const UniDescriptions = ({uniName}) => {
  var description = null;
  const contactNumber = NZ_GOWNING_STREET_PHONE_NUMBER;

  switch(uniName){
    case "Waikato Uni Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>WAIKATO UNI GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165122/Uni%20Campus%20Photos/Waikato/Waikato_Main_LS.jpg" alt="University of Waikato building"/>
          <p className="para">Congratulations Waikato Uni graduates, you’ve done it, after many years of study, late night exam cram, nervous waits for exams results, you are finally here, graduation time! So many people helped you along your way, your friends, your parents and your professors and now is a time to celebrate. You will no doubt have family and friends visiting you and you will be organising your dress/suit for the big occasion.</p>
          <p className="para">You will need to organise a place for dinner, your graduation dinner venue and of-course your graduation gown. You have come to right place to organise your Waikato Uni academic gown.</p>
          <h3>ACADEMIC GOWNS AVAILABLE FOR ALL FACULTIES OF WAIKATO UNI</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165122/Uni%20Campus%20Photos/Waikato/Waikato_graduate.jpg" alt="University of Waikato Bachelor graduate photo"/>
          <p className="para">We have available Bachelors, Honors, and Master’s gowns available which are all manufactured in accordance with the University of Waikato Academic Dress policies. Your hat and gown is tailored to your correct size and we also offer easy exchanges if the fitting is slightly off.</p>
          <h3>WHY PURCHASE FROM GOWNING STREET?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165123/Uni%20Campus%20Photos/Waikato/Waikato_LS_3.jpg" alt="University of Waikato building 1"/>
          <p className="para">Why hire your graduation gown for a few hours when you can purchase it for less? Moreover, you have worked immensely hard for that black hat and gown and you deserve to keep it. It is something you might keep for an Alumni get together or pass done to your kids or friends.</p>
          <p className="para">You may also take advantage of our great students discounts, where buying together allows you to save more, check out our <Link to="/save-with-friends">friends deals</Link> here. Our Academic gowns can be shipped NZ-wide for delivery estimates, please see <Link to="/shipping">here</Link> Our range doesn’t just stop at Academic dress, we also supply <Link to="/regalia">graduation teddy bears</Link> and graduation frames.
          </p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165122/Uni%20Campus%20Photos/Waikato/Waikato_LS_4.jpg" alt="University of Waikato graduate archways"/>
          <h3>FURTHER INFORMATION NEEDED?</h3>
          <p className="para">If you have any questions regarding our Waikato Uni gowns range, please see our <Link to="/about">About page</Link>. You may also contact us via phone or email, please visit our <Link to="/contact">contact us</Link> page for further details</p>
        </div>
      )
    break;

    case "AUT Gowns | Auckland University of Technology Gowns" :
      description = (
        <div className="su-description">
          <h2>QUALITY AUT GRADUATION GOWN FOR SALE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">You have done it! Congratulations on your graduation from AUT. It takes years of dedication and determination to get your degree. Your graduation day is a day to celebrate all those years of studying, blossoming friendships and extra-curricular  activities. You are no doubt a different person to the one that had begun their degree many years ago  as a fresh-faced wide-eyed 18 year old. Now you embark on the rest of your life equipped with your degree, some of you will travel and some of you will begin that dream job, some of you may just take some out to smell the roses,  whatever you do, it doesn’t matter though in the end, what matters most is that you enjoy each day that comes, making the most of the rest of your life!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of AUT graduate"/>
          <p className="para">Soon you will be organising your graduation ceremony, details like who to invite and where to have your graduation dinner will be swirling through your mind. You will need to organise your photography and your ceremony tickets and as such need the proper outfit to do so. At Gowning Street we have all the different types of  AUT gowns that takes care of all Bachelor and Master degrees, and better yet we have it at affordable prices.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Uni%20Campus%20Photos/AUT/aut-graduates-celebrating.jpg" alt="Photo of AUT graduates celebrating"/>
          <h3>WHAT ARE THE ADVANTAGES OF PURCHASING YOUR AUT GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of AUT graduates"/>
          <p className="para">Buy purchasing your AUT Academic dress you get to keep the grad gown as a lifelong momento. It something you can show your grandchildren one day.</p>
          <p className="para">If your friends and/or family attend AUT they will be able to use it as well</p>
          <p className="para">Save time and forget queuing up for hours on graduation day, you will have peace of mind by pre-purchasing your graduation gown. This will allow you to enjoy the moment and avoid the hustle and bustle of your special day.  </p>
          <p className="para">Another benefit of purchasing your AUT academic gown is the flexibility to take your graduation photography at a time convenient to you, not the 2 hour window that hiring provides.  No longer, do you have to rush this process to avoid the late hire fee.</p>
          <p className="para">If you get together with your friends, enjoy our <Link to="/save-with-friends">student discounts</Link> on every set that’s bought</p>
          <p className="para">Lastly, we will have your gown shipped directly to your door, using NZ Post. If you order before 12pm you will get <Link to="/shipping">same day dispatch</Link></p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of AUT graduate with parents"/>
          <h3>SUPPLYING ACADEMIC DRESS FOR STUDENTS ALL OVER NEW ZEALAND</h3>
          <p className="para">Our range isn’t limited to just graduation gowns for AUT – we are proud to supply attire to suit students graduating from the <Link to="/waikato-uni">University of Waikato</Link> as well. We also provide graduation teddy bears and graduation frames. </p>
        </div>
      )
    break;

    case "Auckland Uni Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>QUALITY AUCKLAND UNI GRADUATION GOWN FOR SALE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">Congratulations Auckland Uni graduates, you have graduated from NZ’s #1 ranked uni and top 100 in the world! You will no doubt be preparing for your upcoming graduation ceremony. Some things you will need to consider are organising the hiring/ purchasing of your  Auckland Uni graduation gown, graduation photography, and purchasing your graduation frame. You have come to the right place to purchase your Auckland Uni graduation gown. We sell premium quality, handcrafted regalia at affordable prices. If you order before 1pm, you will receive your order next day (Auckland Based Customers). Our gowns are manufactured in accordance with the Auckland University academic dress policy and we offer easy exchanges if you need to change sizing</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>ACADEMIC DRESS HIRE V PURCHASING YOUR AUCKLAND UNI ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">At Gowning Street we sell quality Auckland University academic dress for $99. By purchasing your Auckland Uni academic dress off us, you can organise your graduation photography at a time which suits rather than the hire window. You are also able to pass down your hat and gown to friends and family for their ceremony or you may simply want to hold on to it as a memento given it’s special significance in your life. Check out our <Link to="/save-with-friends">friends deals</Link> and save up to $10 per set. So when your are considering your Auckland University Academic Dress Hire options, why not give thought to the option of purchasing your gown?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>SUPPLYING GRADUATION REGALIA FOR STUDENTS ALL OVER NEW ZEALAND</h3>
          <p className="para">We offer graduation regalia to various other institutions across NZ like the University of Waikato and Auckland University of Technology. Check our wholesale page for <Link to="/wholesale">bulk discounts</Link>. We use NZ couriers for fast delivery, which means for our Auckland-based customers you can receive your graduation regalia next day if your order before 1pm. For any other enquiries, please contact our friendly Gowning Street Support team at {contactNumber}</p>
        </div>
      )
    break;

    case "Victoria University of Wellington Graduation Gown Sets | VUW Gowns" :
      description = (
        <div className="su-description">
          <h2>VICTORIA UNIVERSITY OF WELLINGTON GRADUATION GOWN SETS FOR SALE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">Congratulations VUW graduates on your outstanding achievement! Preparing for your upcoming ceremony or home graduation? Some things you will need to consider are organising the hiring or purchasing of your VUW graduation gown, graduation photography and purchasing your graduation frame. You have come to the right place to purchase your VUW regalia. We sell premium quality, handcrafted regalia at affordable prices. . Our gowns are manufactured in accordance with the Victoria University of Wellington academic dress policy and we offer easy exchanges if you need to change sizing</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>ACADEMIC DRESS HIRE V PURCHASING YOUR VUW GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">At Gowning Street we sell quality, affordable VUW academic dress for $99. By purchasing your VUW Uni academic dress off us, you can organise your graduation photography at a time which suits rather than being limited by the strict hiring window imposed by the University. You are also able to pass down your hat and gown to friends and family for their ceremony or you may simply want to hold on to it as a memento given it’s special significance in your life. Check out our <Link to="/save-with-friends">friends deals</Link> and save up to $10 per set if you wish to access our special discount. So when your are considering your VUW University Academic Dress Hire options, why not give thought to the option of purchasing your gown instead?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>SUPPLYING GRADUATION REGALIA FOR NEW ZEALAND, AUSTRALIA AND THE UK</h3>
          <p className="para">We offer graduation regalia to various other institutions across NZ like the <Link to="/waikato-uni">University of Waikato</Link> and <Link to="/aut">Auckland University of Technology</Link>. Check our wholesale page for <Link to="/wholesale">bulk discounts</Link>. We use NZ couriers for fast and reliable delivery. For any other enquiries, please contact our friendly Gowning Street Support team at {contactNumber}</p>
        </div>
      )
    break;

    case "University of Canterbury Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>QUALITY AFFORDABLE UNIVERSITY OF CANTERBURY REGALIA FOR SALE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">Congratulations on graduating from the University of Canterbury! To prepare for your upcoming ceremony or home graduation, you will need to consider are organising the hiring or purchasing of your Canterbury graduation gown, graduation photography and purchasing your graduation frame. You have come to the right place - we sell premium quality, handcrafted regalia at affordable prices. Our gowns are manufactured in accordance with the University of Canterbury academic dress policy. We offer a flexible exchange policy.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>WHY PURCHASE YOUR CANTERBURY GOWN RATHER THAN RENTING?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">At Gowning Street we sell quality, affordable Canterbury academic dress for $99. By purchasing your Canterbury Uni academic dress rather than hiring, you can organise your graduation photography at a time which suits and be able to flexibly plan your special day. You can pass down your regalia to friends and family down the track and keep it forever as a memory of your achievement. Rember to look at our <Link to="/save-with-friends">friends deals</Link> page to save up to $10 per set. So when considering your Canterbury University Academic Dress Hire options, why not purchase your regalia instead? Compared to existing options, purchasing might be even cheaper!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>SUPPLYING GRADUATION GOWNS IN NEW ZEALAND, <a href="https://www.gowningstreet.com.au">AUSTRALIA</a> AND THE <a href="https://www.gowningstreet.co.uk">UK</a></h3>
          <p className="para">We have a successful history of selling graduation gowns around the world. This includes other institutions across NZ such as <Link to="/waikato-uni">University of Waikato</Link> and <Link to="/auckland-uni">Auckland University</Link>. Check our wholesale page for <Link to="/wholesale">bulk discounts</Link>, especially if you represent a school or college. We use NZ couriers for fast and reliable delivery. For any other enquiries, please <Link to="/contact">contact us</Link></p>
        </div>
      )
    break;

    case "Massey University of New Zealand Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>MASSEY UNIVERSITY OF GRADUATION GOWNS FOR SALE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">Congratulations on graduating from Massey University! For a stellar ceremony or home graduation, you will need to organise hiring or purchasing of your Massey graduation gown, graduation photography and your degree certificate frame. Gowning Street can cater to all of your graduation needs by supplying quality handcrafted regalia and degree frames at affordable prices. Our gowns are manufactured in accordance with the Massey University academic dress policy.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>WHY PURCHASE YOUR MASSEY GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">At Gowning Street we sell affordable yet high quality Massey academic dress for as little as $89 for a gown and hat set. By buying your Massey Uni academic dress online rather than hiring, you have complete flexibiilty on your graduation day to organise photography. Down the track, you can give your regalia to friends and family or keep it to remember your achievement. Remember to visit our <Link to="/save-with-friends">friends deals</Link> page if you want to save up to $10 per set. So instead of going down the path of Massey University Academic Dress Hire, why not purchase your graduation regalia instead. It might even be cheaper!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>BUY GRADUATION GOWNS ONLINE IN NEW ZEALAND, <a href="https://www.gowningstreet.com.au">AUSTRALIA</a> AND THE <a href="https://www.gowningstreet.co.uk">UK</a></h3>
          <p className="para">We have now launched our popular service around the world. We also sell to other Universities in NZ including the <Link to="/waikato-uni">University of Waikato</Link> and <Link to="/auckland-uni">Auckland University</Link>. If you're a school or college, checkout out our <Link to="/wholesale">bulk discounts</Link> offering. We use NZ couriers for fast and reliable delivery. For any other enquiries, please <Link to="/contact">contact us</Link></p>
        </div>
      )
    break;

    case "Lincoln University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>BUY LINCOLN UNIVERSITY OF GRADUATION GOWN SETS ONLINE AND SAVE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">Congratulations on finishing your degree at Lincoln University! For an unforgettable ceremony or home graduation with family, you will need to hire or purchase your Lincoln graduation gown. You may also want to oranise graduation photography and buy a degree certificate frame for your testamur. Gowning Street is one-stop online store that can cater to all of your graduation requirements by supplying academic regalia and degree frames at affordable prices. Our gowns are manufactured in accordance with the Lincoln University academic dress policy.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>WHY BUY YOUR MASSEY GOWN ONLINE?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">At Gowning Street, we deliver quality academic gowns to your door for as little as $89 for a gown and hat set. By buying your Lincoln Uni graduation gown online rather than hiring, you have total freedom on your graduation day so that you can enjoy your special day rather than waste it lining up for your rental gown. Furthemore, you can pass on your regalia to siblings or friends in another year group or keep it as a memorabilia. We also have <Link to="/save-with-friends">friends deals</Link> available which means you can save up to $10 per set. So instead of considering Lincoln University Academic Dress Hire, save the hassle and buy online instead!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>THOUSANDS OF HAPPY CUSTOMERS FOR OUR GRADUATION GOWNS ACROSS NEW ZEALAND, <a href="https://www.gowningstreet.com.au">AUSTRALIA</a> AND THE <a href="https://www.gowningstreet.co.uk">UK!</a></h3>
          <p className="para">We have thousands of happy customers around the world! Within New Zealand, we sell to many Universities including the <Link to="/waikato-uni">University of Waikato</Link> and <Link to="/auckland-uni">Auckland University</Link>. If you need regalia in bulk for a school or college, checkout out our <Link to="/wholesale">wholesale</Link> offering. We use NZ couriers for fast and reliable delivery. For any other enquiries, please <Link to="/contact">contact us</Link></p>
        </div>
      )
    break;

    case "University of Otago Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>UNIVERSITY OF OTAGO GRADUATION REGALIA</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">Just finished your studies at the University of Otago? To celebrate in style at your ceremony or home graduation, you will need to hire or buy your Otago graduation gown. This can be accompanied by a graduation photography session with family, a degree certificate frame for your testamur and even a graduation teddy. Gowning Street is one-stop online graduation store catering to all of your graduation needs. We supply academic regalia, graduation gifts and degree frames at very reasonable prices. Our gowns are manufactured in accordance with the University of Otago's academic dress policy.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>WHY BUY YOUR OTAGO UNI GOWN ONLINE?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">Gowning Street delivers quality graduation gowns to your door for as little as $89 for a gown and hat set. If you buy your Otago Uni graduation gown online rather than hiring, you won't need to worry about lining up to receive or return your rental regalia on the day. In addition, you can lend your regalia to friends or family in another year group or keep it as a memorabilia for decades to come. We also have <Link to="/save-with-friends">friends deals</Link> available which means you can save up to $10 per set. So instead of hiring from Otago University Academic Dress Hire, save the hassle and buy online instead!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>HAPPY CUSTOMERS GLOBALLY, NOW SELLING REGALIA IN NEW ZEALAND, <a href="https://www.gowningstreet.com.au">AUSTRALIA</a> AND THE <a href="https://www.gowningstreet.co.uk">UK!</a></h3>
          <p className="para">Thousands of happy customers have bought our graduation gowns around the world! In New Zealand, we sell to many Universities including the <Link to="/waikato-uni">University of Waikato</Link> and <Link to="/auckland-uni">Auckland University</Link>, as well as to colleges, schools and institutions. If you want to buy wholesale,  checkout out our <Link to="/wholesale">wholesale</Link> offering. We use NZ couriers for fast and reliable delivery. For any other enquiries, please <Link to="/contact">contact us</Link></p>
        </div>
      )
    break;

    case "Wintec Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>BUY WINTEC GRADUATION GOWN SETS ONLINE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduates.jpg"/>
          <p className="para">About to graduation from Wintec? To complete your graduation experience as part of a ceremony or home graduation, you will need a Wintec graduation gown, hat and hood. Many students also book a graduation photography session with family and buy a degree certificate frame for their testamur and even a graduation teddy. Gowning Street is an online graduation store designed to meet all your graduation needs. We supply premium quality academic dress, graduation gifts and degree certificate frames at affordable prices. Our gowns are manufactured in accordance with the Wintec's academic dress policy.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Uni%20Campus%20Photos/AUT/aut-graduate-wearing-graduation-gown.jpg" alt="Photo of Auckland Uni graduate"/>
          <h3>WHY BUY YOUR WAIKATO INSTITUTE OF TECHNOLOGY REGALIA?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Auckland Uni graduates"/>
          <p className="para">Gowning Street delivers premium graduation gowns to your door for as little as $89 for a gown and hat set. If you buy your Wintec regaila online rather than hiring, you will no longer need to line up for hours to rent on the day. In addition, you can lend your regalia to friends or family or keep it as a lifelong memorabilia. We also have <Link to="/save-with-friends">friends deals</Link> allowing you to save up to $10 per set. So instead of hiring from Wintec Academic Dress Hire, save yourself the trouble and buy online instead!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Auckland Uni graduate with parents"/>
          <h3>HAPPY CUSTOMERS GLOBALLY IN NEW ZEALAND, <a href="https://www.gowningstreet.com.au">AUSTRALIA</a> AND THE <a href="https://www.gowningstreet.co.uk">UK!</a></h3>
          <p className="para">Thousands of happy customers have bought our regalia all around the world! We sell to many Universities in New Zealand including the <Link to="/waikato-uni">University of Waikato</Link> and <Link to="/auckland-uni">Auckland University</Link>. We also sell to colleges, schools and institutions with <Link to="/wholesale">wholesale</Link> offerings available. For fast and reliable delivery, we use NZ couriers . For any other enquiries, please don't hesistate to <Link to="/contact">contact us</Link></p>!
        </div>
      )
    break;

    default:
      description = null;

  }

  return (
    <div className="uni-description">
      {description}
    </div>
  )
}

export default UniDescriptions;
