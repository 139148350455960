import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const BuyFrames = ({name}) => {

  return (
    <div className="shop-products">
      <Helmet
        title="Buy New Zealand Certificate Frames Online and Save | From $49"
        meta={[
          {"name": "description", "content": "Certificate Frames made for New Zealand University Testamurs. Frame your University Graduation Certificate"},
        ]}
      />
        <h1>Buy Certificate Frames online for New Zealand Degrees</h1>
        <div className="subheading">
          <h2>Quality modern and pine wood certificate frames from $49</h2>
        </div>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1561279573/Frames/2_Frames_Image.png" alt="University degree certificate frames" className="topimage"/>
        <div className="frame-unis">
        <div className="frame-uni-box">
            <Link to="/certificate-frame/auckland-uni">Auckland Uni</Link>
          </div>          
          <div className="frame-uni-box">
            <Link to="/certificate-frame/aut">AUT</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/canterbury-uni">Canterbury University</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/lincoln-uni">Lincoln University</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/massey-uni">Massey University</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/mit-manukau">MIT Manukau</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/nmit">NMIT</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/open-polytechnic">Open Polytechnic</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/university-of-otago">Otago University</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/toi-ohomai-tech">Toi Ohomai</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/victoria-university-of-wellington">Victoria University of Wellington</Link>
          </div>
          <div className="frame-uni-box">
            <Link to="/certificate-frame/waikato">Waikato</Link>
          </div>                  
          <div className="frame-uni-box">
            <Link to="/certificate-frame/whitireia-weltec">Whitireia and Weltec</Link>
          </div>                    
          <div className="frame-uni-box">
            <Link to="/certificate-frame/wintec">Wintec</Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>HIGH QUALITY, AFFORDABLE CERTIFICATE FRAMES</h2>
          <p>Completing your University degree is a significant life milestone and deserves to be cherished and memorialised!</p>
          <p>Your University testamur can lose colour over time. The best way to preserve it is by placing it inside a degree certificate frame which will ensure it maintains its colour over time</p>
          <p>We have designed our degree certificate frames to precisely fit your University testamur and make it stand out
          </p>
          <p>We have elegantly designed 2 frame styles - one in a modern black and one handcrafted using pine wood</p>
          <p>We control the design and manufacture our own frames,which means we cut out the middle-man and make sure you get the best quality and value for money</p>
        </div>
      </div>
  )
};

export default BuyFrames;
