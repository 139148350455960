import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShippingTable from '../info/shipping-calculator';
import ShippingCalc from '../../../functions/shippingcalc';

class ShippingDetails extends Component {
  constructor(props) {
    super(props);
    const customer = props.customer;
    const order = props.order;
    const country = props.customer.country;
    let defaultShipping = "standard";

    if (country !== "New Zealand") {
      defaultShipping = "international"
    }

    let qty = 0;

    order.forEach(function(element){
      qty += Number(element.qty);
    });

    this.state = {
      shipping : customer.shipping || defaultShipping,
      qty : qty
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.handleSubmit("customer");
  }

  handleChange(event) {
    this.setState({
      shipping: event.target.value,
    });

    this.props.updateState("shipping", event.target.value);
  }

  handleSubmit() {
    this.props.handleSubmit("confirmation");
  }

  toggleState(index, event) {
    var description = document.getElementById(index);
    var button = document.getElementById("toggle-shipping");

    if(description.style.display === "none"){
      description.style.display = "";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/minus_box_grey_24x24.png";
    }
    else {
      description.style.display = "none";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png";
    }
  }

  render() {
    const hidden = { display : "none" };
    const country = this.props.customer.country;
    const shippingDetails = ShippingCalc(this.props.order,this.props.customer);

    return (
      <div className="shipping-details">
        <p
          onClick={this.goBack}
          className="link"
        >
          Back to Customer Details
        </p>
        <h2>Select Shipping</h2>
        { country === "New Zealand" ? 
        <select
          className="dropdown"
          value={this.state.shipping}
          onChange={this.handleChange}
        >
          <option value="standard">
            Standard Shipping
          </option>
          <option value="express">
            Express Shipping
          </option>
        </select>
        :
        <select
          className="dropdown"
          value={this.state.shipping}
          onChange={this.handleChange}
        >
          <option value="international">
            International
          </option>
        </select>
        }
        <p className="shipping-price">
        Total Shipping Cost : ${shippingDetails.total}
        </p>
        <button onClick={this.handleSubmit}>Continue</button>
        <div className="shippingdes">
          <div className="shipping-header" onClick={this.toggleState.bind(this, "shippingtable")}>
            <img id="toggle-shipping" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png" />
            <h4>Shipping Guide:</h4>
          </div>
          <div id="shippingtable" style={hidden}>
            <ShippingTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingDetails);
