import React from 'react';
import { withFormsy } from 'formsy-react';

const CustomInput = (props) => {
  const changeValue = (event) => {
    props.setValue(event.currentTarget.value);
    props.handleChange(event.currentTarget.value);
  }
  const className = props.showRequired ? 'required' : props.showError ? 'error' : null;
  const errorMessage = props.errorMessage;

  return (
    <div className={className} style={{ marginBottom: '12px' }}>
      <input
        type="text"
        placeholder={props.placeholder}
        onChange={changeValue}
        value={props.value || ''}
      />
      {props.isFormSubmitted && !props.isValid && <span style={{display: 'block', color: '#cc0000', fontSize: '12px', padding: '2px 3px'}}>{errorMessage}</span>}
    </div>
  )
}

export default withFormsy(CustomInput);


