import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const BuyLegalwear = ({name}) => {

  return (
    <div className="shop-products">
      <Helmet
        title="Looking to Buy A Graduation Gift? Shop Now at Gowning Street"
        meta={[
          {"name": "description", "content": "We have an exquisite range of Graduation Gifts at Great Prices! Fast Shipping NZ-Wide."},
        ]}
      />
      <h1>Buy Graduation Gifts Online | Fast Delivery NZ Wide!</h1>
      <div className="subheading">
        <h2>Complete your graduation experience with a graduation teddy bear, frame or hat!</h2>
      </div>
      <div className="catalogue">
        <div className="img-container">
          <div className="img-box">
            <Link to="/regalia/graduation-puppy-small">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Small</strong>
                  <p>$19</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-puppy-large">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Large</strong>
                  <p>$25</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-teddy-bear">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" alt="graduation bear"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Teddy Bear</strong>
                  <p>$19</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/regalia/graduation-hat">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg" alt="graduation hat"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Hats</strong>
                  <p>$49</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/certificate-frame">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" alt="Degree certificate frame"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Certificate Frames</strong>
                  <p>$49</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>Graduation Gifts At Gowning Street</h2>
          <p>Gowning Street has excellent graduation gift ideas. When buying a graduation gift, you want to buy something that really marks this special occasion as best as possible. We have available beautifully crafted graduation certificate frames, graduation teddy bears, and many more excellent grad gift ideas. Our aim is to make graduation that much more special.
          </p>
          <h2>Wanting to Purchase a Graduation Present?</h2>
          <p>Are you wanting to purchase a graduation present? Look no further, you have come to the right place. Gowning Street Stocks excellent graduation presents. We spend a considerable amount of time every year to find the best graduation gift ideas.We know this is a very special moment And we make it our job to make it that much more special. Where possible, we try and find local suppliers who custom manufacture unique graduation gifts. We really try to embed that special Kiwi flavour in our products.</p>
          <h2>Wanting Graduation Gifts for Boys?</h2>
          <p>Sometimes it's hard to know what the right graduation gift for a boy is. When trying to choose a graduation gift for him, a practical gift idea could be one of our premium handcrafted graduation certificate frames. These frames are made to ensure that the graduation certificate is preserved immaculately lifelong.</p>
          <h2>Graduation Gift for Girls? We Have you Covered!</h2>
          <p>Trying to find that perfect graduation gift for her? Gowning Street has some delightful graduation gift for girls. Many people find that our graduation teddy bears and puppies are adorable and are a great addition to graduation photos. We ship New Zealand-Wide, and if you order before 12 p.m, we are able offer same-day dispatch. Generally items take 1 to 2 days to reach towns/cities in the North Island and two to three days for towns / cities in the South Island.</p>
          <h2>We have Great Uni Graduation Gift Ideas</h2>
          <p>If you are looking for a uni graduation gift, you have come to the right place. At Gowning Street we look to supply amazing uni graduation gifts. We do all the searching and sourcing of creative graduation gifts ideas. Have a browse of our range, there is something for everyone. Order easily online, and have your item delivered directly to your door or to your recipient’s address.</p>
          <h2>University Graduation Gift Options</h2>
          <p>If you are wanting to purchase a University graduation gift, we have some amazing choices. For example, our elegantly crafted pine- wood certificate frames would be an ideal choice in preserving your special someone's graduation certificate. We also stock a range of  grad teddies, grad puppies and other items that make graduation that much more memorable. Get in touch with someone from our crew and we can bounce some ideas!</p>
          <h2>A Graduation Gift for Friend; Is a Thoughtful Gesture</h2>
          <p>A graduation gift for a friend is a beautiful way to acknowledge your friends significant achievement.. Your friend will appreciate your graduation gift forever. This marks a special occasion in their life and there are many graduation gifts that you may consider for your friend. A graduation certificate frame is one of our best sellers,  these frames are made of premium pine wood and will ensure that your friend's graduation certificate will be well preserved for a long time to come. We also have super cute teddy bears and graduation puppies available too.</p>
          <h2>Graduation Gift for Daughter to Mark her Special Day</h2>
          <p>If you are a proud parent looking for a graduation gift for your daughter - look no further,. at Gowning Street we have something for everybody. Where possible, we look for local suppliers for our grad gifts. Sometimes it can be tricky to find the right graduation gift for her; At Gowning Street we make this easy for you as we stock a wide array of products. We also like to include local kiwi suppliers who make unique pieces that will truly convey your appreciation.</p>
          <h2>Graduation Gift for Son; He Deserves it</h2>
          <p>A graduation gift for your son is the perfect way to show your appreciation for the hard work he has put in. Parents you should be proud of yourself too, you played no small part in helping your son achieve this very special moment in his life. When looking for a graduation gift for him, we have available a range of grad gift ideas. If you want him to look smart on graduation day, we have a premium range of  ties and cufflinks to choose from and many more items of interest.</p>
        </div>
      </div>
    </div>
  )
};

export default BuyLegalwear;
