import React from 'react';

const ExchangeConfirmation = (props) => (
  <div>
    <h1>Exchange Confirmed</h1>
    <div className="exchangeConfirmation">
      <p>Thank you, we have received your exchange request.</p>
      <p>Your exchange reference number is: <span className="emphasizeSpan">EXCH{props.find_ordernum}</span></p>
      <p>You will receive an email confirmation shortly. </p>
      <p>Please allow 1 - 2 hours. <span className="emphasizeSpan" style={{ color: 'red' }}>Remember to check your junk/spam folder</span></p>
      <p>Once your return parcel has been verified, we will dispatch the new items you have requested</p>
    </div>

  </div>
);

export default ExchangeConfirmation;