import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const UniRegData = require("../../sources/uni-regulations");

const Regulations = ({match}) => {
  const Uni = match.params.uni;
  const title = "Academic Dress Regulations at " + Uni;

  return (
    <div className="regulations">
      <Helmet
        title={title}
        meta={[
          {"name": "Academic regalia regulations at " + {Uni}, "content": "Description of academic dress regulations at " + {Uni}},
        ]}
      />
      <h1>{Uni} Academic Dress Regulations</h1>
      <p>Graduation regalia at {Uni} is based on the  modern version of a 12th century tradition among medieval monks. This intricate regalia represents intellectual attainment, scholarship and achievement – hallmarks of all {Uni} students. {Uni} Academic regalia is worn at your graduation ceremony and other formal occasions to represent the long standing academic traditions and achievements of {Uni}</p>

      <h2>{Uni} Graduation Gown</h2>
      <p>At {Uni} University: Bachelors wear the Cambridge Bachelor of Arts style of black gown. Masters wear the Cambridge Master of Arts style of black gown. The Masters style gown main difference from the Bachelors style is that it has a longer sleeve. {Uni} Doctorates and PhD regalia are designed differently.</p>

      <h2>{Uni} Graduation Hat</h2>
      <p>{Uni} graduation hat design is based on the square hat  with three flat projections on top worn by Roman Catholic clergyman in the middle ages. The graduation hat contains a level square board attached upon a skull hat with a black tassel adjusted to the middle. This ‘motorboard’ design is worn by both Masters and Bachelor graduates. [uni name] philosophy graduates  wear a Tudor bonnet which is made of velvet and lined with satin. This hat has a tassel hanging from a cord encircling the hat</p>

      <h2>Academic Dress Tailoring at Gowning Street</h2>
      <p>At Gowning Street, our passion lies in quality academic dress manufacturing. This is reflected in the preciseness of our design and in the high standard of our fabrics. At the same time, we work hard to ensure are gowns are manufactured to a comfort look and feel.</p>
      <p>Our quick delivery, bulk discounts and superior fitting allow you to have a relaxing time at your graduation ceremony. You can take your graduation photography at ease without worrying about returning it within the two-hour graduation gown hire window. Buying from Gowning Street is better than the over priced option of graduation gown hire and you get to cherish your academic dress forever. </p>
      <p></p>
    </div>
  )
}

export default Regulations;