import React, { Component } from 'react';
import Products from '../../sources/products';

class Gown extends Component {
  constructor(props) {
    super(props);
    const path = window.location.pathname;
    const level = props.level;
    const phdStyle = props.phdStyle;
    var selections;

    if(level === "Bachelor") {
      selections = ["GOW00001", "GOW00002", "GOW00003", "GOW00004", "GOW00005"];
    }
    else if(level === "Master") {
      selections = ["GOW00011", "GOW00012", "GOW00013", "GOW00014", "GOW00015"];
    }
    else if(level === "PhD") {
      switch(phdStyle){
        case "cloth":
          selections = ["GOW00021", "GOW00022", "GOW00023", "GOW00024", "GOW00025"];
        break;

        default:
          selections = ["GOW00031", "GOW00032", "GOW00033", "GOW00034", "GOW00035"];
      }
    }
    this.state = {
      selections : selections,
      selectedValue : selections[2]
    };
  }

  componentWillReceiveProps() {
    var path = window.location.pathname;
    var level = Products[path].level;
    var phdStyle = this.props.phdStyle;

    var selections;

    if(level === "Bachelor") {
      selections = ["GOW00001", "GOW00002", "GOW00003", "GOW00004", "GOW00005"];
    }
    else if(level === "Master") {
      selections = ["GOW00011", "GOW00012", "GOW00013", "GOW00014", "GOW00015"];
    }
    else if(level === "PhD") {
      switch(phdStyle){
        case "cloth":
          selections = ["GOW00021", "GOW00022", "GOW00023", "GOW00024", "GOW00025"];
        break;

        default:
          selections = ["GOW00031", "GOW00032", "GOW00033", "GOW00034", "GOW00035"];
      }

    }

    var selectedValue;

    if(level === "Bachelor" && this.state.selectedValue[6] == 1){
      this.setState({
        selectedValue : "GOW00003"
      });
    }
    else if (level === "Master" && this.state.selectedValue[6] == 0){
      this.setState({
        selectedValue : "GOW00013"
      });
    }

    this.setState({
      selections : selections
    });
  }

  handleChange(event) {
    this.setState({
      selectedValue: event.target.value,
    });

    this.props.setGown(event.target.value);
  }

  render() {
    return (
      <div className="gown-size">
        <h3>Choose your Gown size</h3>
        <p className="sizedesc">Based on your height</p>
        <select
          className="select-size"
          value={this.state.selectedValue}
          onChange={this.handleChange.bind(this)}
        >
          <option value={this.state.selections[0]}>Extra Small (Less than 158cm)</option>
          <option value={this.state.selections[1]}>Small (159 - 164cms)</option>
          <option value={this.state.selections[2]}>Medium (165 - 170cms)</option>
          <option value={this.state.selections[3]}>Large (171 - 177cms)</option>
          <option value={this.state.selections[4]}>Extra Large (Greater than 178cms)</option>
        </select>
      </div>
    );
  }
}

export default Gown;
