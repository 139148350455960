import React from 'react';
import styled from 'styled-components'
import { Column, Container } from '../../components/Layout';
import { AlphaHeader } from '../../components/Section';
import { mediaQueries } from '../../utils/mediaQueries';
import { Link } from 'react-router-dom';
import {
  BACHELOR_SET_PRICE,
  BACHELOR_GOWN_PRICE,
  MASTER_GOWN_PRICE,
  CAP_PRICE,
  HOOD_PRICE,
  TEDDY_SMALL_PRICE
} from '../../../constants';

const products = [
  {
    id: 1,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1592535319/Uni%20Regalia%20Photos/Auckland/auckland-bach-nurse-side.jpg',
    header: 'Auckland Uni Gowns',
    description: 'Full University Graduation Gown Sets',
    link: '/auckland-uni',
    price: `$${BACHELOR_SET_PRICE}`,
  },
  {
    id: 2,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Uni%20Regalia%20Photos/Waikato/waikato-bach-hons-side.jpg',
    header: 'Waikato Uni Gowns',
    description: 'Full University Graduation Gown Sets',
    link: '/waikato-uni',
    price: `$${BACHELOR_SET_PRICE}`,
  },
  {
    id: 3,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Uni%20Regalia%20Photos/AUT/aut-bach-arts-side.jpg',
    header: 'AUT Gowns',
    description: 'Full University Graduation Gown Sets',
    link: '/aut',
    price: `$${BACHELOR_SET_PRICE}`,
  },
  {
    id: 4,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg',
    header: 'Bachelor Style Gown',
    description: 'Graduation Gown - Bachelor Style',
    link: '/regalia/bachelor-gown',
    price: `$${BACHELOR_GOWN_PRICE}`,
  },
  {
    id: 5,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg',
    header: 'Master Style Gown',
    description: 'Graduation Gown - Master Style',
    link: '/regalia/master-gown',
    price: `$${MASTER_GOWN_PRICE}`,
  },
  {
    id: 6,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/hood.jpg',
    header: 'Graduation Hoods',
    description: 'Choose graduation hoods from a variety of colours',
    link: '/regalia/graduation-hoods',
    price: `$${HOOD_PRICE}`,
  },
];

const SectionConainerWhite = styled.div`
  padding: 70px 15px 80px 15px;
  background-color: #FFFFFF;
  ${mediaQueries.tablet`
    padding: 20px;
  `};
`
const StyledProductRow = styled.div`
  display:flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  ${mediaQueries.tablet`
    div:nth-child(5) {
      display: none;
    }
    margin: 15px auto;
  `};
  ${mediaQueries.mobile`
    margin: 0 auto;
  `};
`

const Title = styled.div`
  display: inline-block;
  p {
    font-weight: 700;
  }
`

const StyledLink = styled(Link)`
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #041733;
`

const ProductContainer = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 13rem;
  min-height: 25rem;
  padding: 15px 5px;
  border-radius: 4px;
  &:hover {
    box-shadow: 2px 5px 10px #888888;
  }
  p {
    line-height: 20px;
    margin-top: 14px;
  }
  ${mediaQueries.tablet`
    padding: 20px 63px;
  `};
`

const ProductImg = styled.div`
  padding: 15px 20px 10px 20px;
  img {
    height: 250px;
    border-radius: 4px;
  }
`

const BtnContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 5px 0;
  button {
    font-size: 16px;
    background-color: white;
    color: black;
    width: 160px;
    height: 50px;
    padding: 0px;
    border: none;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px #888888;
    text-align: center;
    margin-top: 5px;
  }
   ${mediaQueries.tablet`
      margin: 0 0 30px 0;
      button {
        font-size: 15px;
      }
  `};
   ${mediaQueries.mobile`
      button {
        font-size: 15px;
      }
  `};
`

const Steps = ({ steps }) => (
  <StyledProductRow>
    {
      products.map((product) => (
        <Column key={product.id}>
          <StyledLink to={product.link}>
            <ProductContainer>
              <ProductImg><img src={product.icon} /></ProductImg>
              <Title>
                <p>{ product.header }</p>
              </Title>
              <p>{ product.description }</p>
              <p>{ product.price }</p>
            </ProductContainer>
          </StyledLink>
        </Column>
        )
      )
    }
  </StyledProductRow>
)

const PopularProdcts = () => {
  return (
    <SectionConainerWhite>
      <AlphaHeader>Popular Products</AlphaHeader>
      <Steps products={products} />
      <BtnContainer to="/regalia"><button>Shop Now</button></BtnContainer>
    </SectionConainerWhite>
  )
}

export default PopularProdcts;




