import React, { Component } from 'react';
import Orders from './orders';
import Inventory from './inventory';
import ChangeInv from './changeInv';
import NewInv from './newinv';
import Accounting from './accounting';
import SpecificOrder from './specificOrder';
import BulkUpload from './bulkupload';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn : false,
      orders : null,
      products : null,
      view : "orders",
      password : "",
      error : null
    };

    this.changeView = this.changeView.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    var productReq = new XMLHttpRequest();
    var context = this;

    productReq.addEventListener('load', function(){
      var response = JSON.parse(this.response);

      if(!response.failure){
        var products = JSON.parse(this.response).data;

        context.setState({
          isLoggedIn : true,
          products : products
        });
      }

    });
    productReq.open("GET", "/api/adminapi/products");
    productReq.send();

    var orderReq = new XMLHttpRequest();
    orderReq.addEventListener('load', function(){
      var orders = JSON.parse(this.response).data;

      context.setState({
        orders : orders
      });

    });
    orderReq.open("GET", "/api/adminapi/orders");
    orderReq.send();
  }

  changeView(event) {
    this.setState({
      view : event.target.getAttribute('data-view')
    });
  }

  handleChange(event) {
    this.setState({
      password : event.target.value
    });
  }

  handleSubmit() {
    var context = this;

    var loginReq = new XMLHttpRequest();
    loginReq.addEventListener('load', function(){
      if(this.response !== "Unauthorized"){
        context.setState({
          isLoggedIn : true
        });
// TODO: this seems weird
        context.componentDidMount();
      }
      else{
        context.setState({
          error : "Incorrect password"
        });
      }

    });
    loginReq.open("POST", "/api/login");
    loginReq.setRequestHeader('Content-Type', 'application/json');
    loginReq.send(JSON.stringify({
      username : "admin",
      password : context.state.password
    }));
  }

  logout() {
    var context = this;

    var loginReq = new XMLHttpRequest();
    loginReq.addEventListener('load', function(){
      context.setState({
        isLoggedIn : false
      });

    });
    loginReq.open("GET", "/api/logout");
    loginReq.send();
  }

  render() {
    const textStyle = { color: "blue" };
    const { isLoggedIn, view, orders, products, password, error } = this.state;

    return (
      <div>
        {isLoggedIn ? (
          <div className="admin">
            <h1>Admin Portal</h1>
              <p>...</p>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="orders" onClick={this.changeView}>View Orders</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="inventory" onClick={this.changeView}>View Inventory</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="changeinv" onClick={this.changeView}>Change Inventory</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="newinv" onClick={this.changeView}>Add New Inventory</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="accounting" onClick={this.changeView}>Accounting</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="specificOrder" onClick={this.changeView}>SpecificOrder</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" data-view="bulkupload" onClick={this.changeView}>Bulk Upload</a>
              <p>...</p>
            <a style={textStyle} className="adminLink" onClick={this.logout}>Logout</a>
              <p>...</p>
              <p>...</p>
            {view === "accounting" ? <Accounting orders={orders}/> : null}
            {view === "specificOrder" ? <SpecificOrder orders={orders}/> : null}
            {view === "orders" ? <Orders orders={orders}/> : null}
            {view === "inventory" ? <Inventory inventory={products}/> : null}
            {view === "changeinv" ? <ChangeInv reload={this.componentDidMount.bind(this)}/> : null}
            {view === "newinv" ? <NewInv reload={this.componentDidMount.bind(this)}/> : null}
            {view === "bulkupload" ? <BulkUpload reload={this.componentDidMount.bind(this)}/> : null}
          </div>
        ) : (
          <div className="admin">
            <p>Password?</p>
            <input
              type="password"
              value={password}
              onChange={this.handleChange}
            />
            <button onClick={this.handleSubmit}>Submit</button>
            <p>{error}</p>
          </div>
        )}
      </div>
    );
  }
}

export default Admin;