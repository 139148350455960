'use strict';

var initialState = {
  firstname : "",
  lastname : "",
  phone : "",
  email : "",
  address_line1 : "",
  address_line2 : "",
  state : "",
  city : "",
  postcode : "",
  country : "",
  referral : "",
  comments : "",
  shipping : ""
};

const Customer = (
  state = initialState,
  action
) => {
  switch(action.type) {
    case 'UPDATE_CUSTOMER':
      var newState = {};
      for (var prop in state){
        if(state.hasOwnProperty(prop)){
          newState[prop] = state[prop];
        }
      }

      newState[action.field] = action.value;

      return newState;

    case 'DROP_CUSTOMER':
      return initialState;

    default:
      return state;
  }
};

export default Customer;