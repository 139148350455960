import React, { Component } from 'react';
import Universities from '../../sources/universities';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import HomeContent from './homecontent';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      searchResults: []
    };
  }

  render() {
    return (
      <div className="homepage">
        <Helmet
          title="Buy Graduation Gown Sets New Zealand | Academic Gowns & Dress from Gowning Street"
          meta={[
            {"name": "description", "content": "Forget renting an academic gown or dress. At Gowning Street you can buy your graduation gowns and save money! Bachelor, master or doctoral gowns shipped to your door throughout New Zealand"},
          ]}
        />
        <div className="home">
          <h1 className="maintitle">Graduation Gowns for $89</h1>
          <h3 className="subtitle">Why hire when you can buy for less?</h3>
          <div className="buttons">
            <Link to="/about"><button>Learn More</button></Link>
            <Link to="/regalia"><button>Shop Now</button></Link>
          </div>
        </div>
        <HomeContent />
      </div>
    );
  }
}

export default Home;