import React, { Component } from 'react';
import NavigationButton from './navigationButton';
import { gownCap } from '../../sources/gowncap';


class ReturnParcel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputDirty: false,
    }

    this.handleBlur = this.handleBlur.bind(this);
    this.printDetails = this.printDetails.bind(this);
  }

  handleBlur() {
    this.setState({ inputDirty: true });
  }

  printDetails() {
    window.print();
  }

  render() {
    const items = this.props.items.map(function(e,i){
      return (
        <li key={"return"+i}>
          {e.from} : {gownCap[e.from]}
        </li>
      )
    })
    return (
      <div className="returnParcel">
          <h1>Step 3: Return Parcel</h1>
          <section>
            <div className="returnDetails">
              <h2>Packaging instructions:</h2>
              <p>Please ensure that all items are neatly folded into their original packaging. To be accepted, the original packaging must show the <strong>correct barcode</strong> for each item as per the printout below:</p>
               <button className="printoutButton" onClick={this.printDetails}>Print Details</button>
            </div>
            <div className="returnDetails">
              <h2>Please include the below printout <strong>inside</strong> your return package</h2>
              <div className="borderedBlock">
                <p>Items to return for Gowning Street order number {this.props.find_ordernum}:</p>
                <ul>{items}</ul>
              </div>
            </div>
            <div className="returnDetails">
              <h2>Return address:</h2>
              <div className="borderedBlock">
                <span>Attn: Gowning Street Pty Ltd</span>
                <span>Green Freight Limited</span>
                <span>Unit C & D/ 28A Verissimo Drive</span>
                <span>Māngere, Auckland 2022, New Zealand</span>
              </div>
            </div>
          </section>

        <p>Once packaging has been sent, please enter tracking number/URL below</p>
        <input
          className="trackingNumberInput"
          type="text"
          placeholder="Tracking Number / URL"
          value={this.props.trackingNumber}
          onBlur={this.handleBlur}
          onChange={(event) => this.props.handleChange(event, "trackingNumber")}
        />
        {
          this.props.inputEmptyError &&
          <span style={{ fontSize: '12px', padding: '15px 30px', color: 'red' }}>
            Please enter the tracking number to proceed
          </span>
        }
        <div className="navigateButtonGroup">
          <NavigationButton handleClick={() => this.props.navigateBack('items') } label="Back" />
          <NavigationButton handleClick={() => this.props.confirmParcelReturned(this.props.trackingNumber)} label="Next" />
        </div>
      </div>
    )
  }

}

export default ReturnParcel;