import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Link } from 'react-router-dom';

import CustomInput from './customInput';
import CustomDropdown from './customDropdown';

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    const { customer } = props;
    this.state = {
      firstname : customer.firstname,
      lastname : customer.lastname,
      phone : customer.phone,
      email : customer.email,
      repeatedEmail : customer.repeatedEmail,
      address_line1 : customer.address_line1,
      address_line2 : customer.address_line2,
      city : customer.city,
      postcode : customer.postcode,
      state : customer.state,
      country : customer.country,
      referral : customer.referral,
      comments : customer.comments,
      canSubmit : false,
      triggerError : false,
    };
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }

  enableButton()  {
    this.setState({
      canSubmit: true,
      triggerError: false,
    });
  }
  disableButton() {
    this.setState({
      canSubmit: false,
      triggerError: true,
    });
  }
  onSubmit() {
    this.props.handleSubmit("shipping");
  }
  handleInvalidSubmit() {
    this.setState({
      triggerError: true,
      canSubmit: false
    })
  }
  handleChange(field, event) {
    var nextState = {};
    nextState[field] = String(event);

    this.setState(nextState);

    this.props.updateState(field, String(event));

    if (field === "country") {
      this.toggleIntlShipping(event)
    }
  }
  toggleIntlShipping(country) {
    if (country !== "New Zealand") {
      this.props.updateState("shipping", "international");
    }
    else {
      this.props.updateState("shipping", "standard");
    }
  }

  renderShippingForm(country) {
    const isInternational = country !== 'New Zealand'
    const postCodeValidation = isInternational ? 'minLength:3' : 'isNumeric,isLength:4'
    return (
      <>
        <h2 className="break">Shipping Details</h2>
        <CustomInput
          name="address_line1"
          placeholder="Address line 1*"
          value={this.state.address_line1}
          validations="maxLength:40"
          validationError="Max 40 Characters"
          handleChange={this.handleChange.bind(this, 'address_line1')}
          required
        />
        <CustomInput
          name="address_line2"
          placeholder="Address line 2 (Optional)"
          value={this.state.address_line2}
          validations="maxLength:60"
          validationError="Max 60 Characters"
          handleChange={this.handleChange.bind(this, 'address_line2')}
        />
        <CustomInput
          name="city"
          placeholder="City/Town*"
          value={this.state.city}
          validations="maxLength:50"
          validationError="Max 50 Characters"
          handleChange={this.handleChange.bind(this, 'city')}
          required
        />
        <CustomInput
          name="state"
          placeholder="State/Region"
          value={this.state.state}
          validations="maxLength:30"
          validationError="Max 30 Characters"
          handleChange={this.handleChange.bind(this, 'state')}
        />
        <CustomInput
          name="postcode"
          placeholder="Postcode*"
          value={this.state.postcode}
          validations={postCodeValidation}
          validationError="Please enter a valid postcode format"
          handleChange={this.handleChange.bind(this, 'postcode')}
          required
        />
        { this.state.triggerError ? (
          <div className="errors">
            <p className="error">Please complete all required fields above</p>
            <p className="error">*Denotes required field</p>
          </div>
          )
        : null }
        <button type="submit">Continue</button>
      </>
    )
  }

  render() {
    return (
      <div className="form-page">
        <Link to="/cart">Back to edit cart</Link>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalidSubmit={this.handleInvalidSubmit}
          className="customerform"
        >
          <h2>Customer Details</h2>
          <CustomInput
            name="firstname"
            placeholder="First name*"
            value={this.state.firstname}
            validations="maxLength:35"
            validationErrors={{ isDefaultRequiredValue: 'This field is required', maxLength: 'Max 35 Characters' }}
            handleChange={this.handleChange.bind(this, 'firstname')}
            required
          />
          <CustomInput
            name="lastname"
            placeholder="Last name*"
            value={this.state.lastname}
            validations="maxLength:35"
            validationError="Max 35 Characters"
            handleChange={this.handleChange.bind(this, 'lastname')}
            required
          />
          <CustomInput
            name="phone"
            value={this.state.phone}
            placeholder="Phone number*"
            validations="maxLength:20"
            validationError="Max 20 Characters"
            handleChange={this.handleChange.bind(this, 'phone')}
            required
          />
          <CustomInput
            name="email"
            validations="isEmail"
            placeholder="Email*"
            validationError="This is not a valid email"
            value={this.state.email}
            handleChange={this.handleChange.bind(this, 'email')}
            required
          />
          <CustomInput
            name="repeatedEmail"
            validations="equalsField:email"
            placeholder="Email Confirmation*"
            validationError="Emails are not identical"
            value={this.state.repeatedEmail}
            handleChange={this.handleChange.bind(this, 'repeatedEmail')}
            required
          />
          <CustomDropdown
            name="referral"
            placeholder="How did you hear about us?*"
            value={this.state.referral}
            handleChange={this.handleChange.bind(this, 'referral')}
            className="last"
          />
          <CustomDropdown
            name="country"
            placeholder="Country*"
            value={this.state.country}
            validations="maxLength:50"
            className="last"
            validationError="Please select a valid country"
            handleChange={this.handleChange.bind(this, 'country')}
            required
          />
          { this.state.country && this.renderShippingForm(this.state.country) }
        </Formsy>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);
