import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => (
  <div className="about">
    <div className="about-contents">
      <Helmet
        title="About our Academic Gowns"
        meta={[
          {"name": "description", "content": "Buy your Academic Gowns from Gowning Street. We have served thousands of happy customers in New Zealand. Fast Delivery. Easy Exchange."},
        ]}
      />
      <h1>About Us</h1>
      <p className="maindes" style={{margin: '0 auto 40px', width: '75%'}}>Gowning Street has been weaving moments of graduation joy with our top-notch, yet wallet-friendly academic gowns.</p>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="University graduates leaving their ceremony"/>
      <p>Thousands of satisfied Kiwis later, we're still here, handcrafting each gown with a sprinkle of love. Because every graduation deserves a touch of excellence, we’re excited to keep outfitting our fellow Kiwis in style!</p>
      <h3>Universities and Students Love Us!</h3>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="University graduates"/>
      <p>Our gowns are more than just fabric; they're a symbol of achievement, tailored to perfection following university guidelines, using only the best materials and vibrant dyes.</p>
      <h3>Speedy Delivery, No Worries</h3>
      <p>At Gowning Street, we team up with NZ Post to get your gown to your doorstep swiftly and smoothly. Check our shipping times at <Link to="/shipping">www.graduationgowns.co.nz/shipping</Link>. Order by 12pm and we'll send it out the same day!</p>
      <p>Orders before 2pm are dispatched same day.</p>
      <h3>Buy Smart, Save Big</h3>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg" alt="University graduates on stairs"/>
      <p>Why rent when you can own? On average, buying your academic attire from Gowning Street is more pocket-friendly than renting from your institution.</p>
      <h3>Graduation Day, Hassle-Free</h3>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-park-bench.jpg" alt="University graduate on park bench"/>
      <p>Forget about queuing up for hours on your big day. Pre-order your gown with us, dodge the queues, and plan your graduation snaps whenever suits you best.</p>
      <h3>A Keepsake for Life</h3>
      <p>Your graduation gown is more than an outfit; it's a chest of memories from late-night study sessions to the invaluable support of your loved ones. It's your journey woven into a keepsake you deserve to treasure forever.</p>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165128/Placement%20Photos/graduate-on-uni-stairs.jpg" alt="University graduate wearing gown set sitting on University steps"/>
    </div>
  </div>
)

export default About;