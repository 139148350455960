import React from 'react';
import { Link } from 'react-router-dom';

const ProductDescriptions = ({ productInfo }) => {

    var description = null;
    let productName = productInfo.name;

    switch(productName){
      case "Graduation Hat" :
        description = (
          <div className="su-description">
            <h2>Hand Crafted Graduation Hats</h2>
            <p className="para">Our Graduation Hat is of excellent quality and design. Grad hats have always signified graduation day. Our graduation caps are the perfect memento for you to keep lifelong recognising the tremendous work in obtaining your academic qualification. You may also pass on your graduation hat to your siblings or friends for their future graduation ceremonies.</p>
            <p className="para">Our Mortarboard is constructed with premium quality polyester and wool felt fabric. The graduation caps are perfect for University, High School and polytechnic graduation ceremonies. Hand crafted to perfection, our Mortarboard Hat is designed with a rigid base, and elastic fit for comfort and fit most head circumferences. We recommend, if you are between sizes, go up a size for your graduation hat.</p>
            <p className="para">Each Mortarboard comes with a corded black tassel and raised button. Our online store makes it effortless to order graduation caps online.</p>
            <p className="para">We sell graduation hats, although some people call them Graduation Mortarboard, Graduation caps, or Graduation trenchers. We also sell other graduation regalia and <Link to="/graduation-gift">graduation gifts</Link>, such as <Link to="/certificate-frame">certificate frames</Link>. Not to mention stand-alone items such as <Link to="/regalia/graduation-hoods">graduation hoods and stoles</Link> are all available to purchase from our online store.</p>
            <p className="para">Our objective is to create a graduation day that is an unforgettable and enjoyable experience and to do this our number one priority is to provide you with the perfect academic regalia.</p>
            <p className="para">For wholesale purchases, please email us directly at <a href="mailto:support@graduationgowns.co.nz">support@graduationgowns.co.nz</a> and team member will be in touch to discuss options.</p>
            <p className="para">Go to our <Link to="/certificate-frame">Certificate Frames</Link> and <Link to="/regalia/graduation-hoods">Graduation Hoods</Link> page to see our database of products available.</p>
            <p className="para">And make sure to check out our extensive range of <Link to="/graduation-gift">Graduation Gifts</Link> available to purchase online. </p>
          </div>
        )
        break;

      default:
        description = null;

    }

    return (
      <div className="uni-description">
        {description}
      </div>
    )

}

export default ProductDescriptions;