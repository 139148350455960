import React, { useState, useEffect } from 'react';
import NavigationButton from './navigationButton';
import { gownCap } from '../../sources/gowncap';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LockSvg from '../../assets/lock.svg'
import Loader from '../custom-items/loader';

const ExchangePayment = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null)

  const {
    items,
    totalCost,
    trackingNumber,
    find_ordernum,
    exchangeCost,
    subtotal,
    shippingPrice,
    itemsExchanged,
    navigateBack,
    firstname ,
    lastname ,
    phone ,
    email ,
    shipping ,
    address_line1 ,
    address_line2 ,
    city ,
    state ,
    postcode ,
    country ,
    confirmPayment
  } = props;

  const customer = {
    firstname,
    lastname,
    phone,
    email,
    shipping,
    address_line1,
    address_line2,
    city,
    state,
    postcode,
    country,
  }

  useEffect(() => {
    try {
      fetch("/api/charge/getPaymentClientSecret", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ customer, total: totalCost })
      })
        .then(function (result) {
          return result.json();
        })
        .then(function (data) {
          setClientSecret(data.clientSecret);
        })
        .catch(() => {
          setError(error)
        })
    } catch (error) {
      setError(error)
    }

  }, []);

  const customerName = `${firstname} ${lastname}`;

  const payWithCard = async () => {
    if (!stripe || !elements) {
      setError({
        errorType: 'stripe',
        errorDetail: null
      });
      return;
    }
    setLoading(true);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: customerName,
        },
      },
      shipping: {
        name: customerName,
        address: {
          line1: address_line1,
          line2: address_line2,
          city: city,
          postal_code: postcode,
          country: 'au'
        }
      },
      receipt_email: email,
    });

    if (result.error) {
      setError({
        errorType: 'stripe',
        errorDetail: result.error.message
      });
      setLoading(false);
      return;
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        fetch("/api/exchange/postPaymentSuccess", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            customer,
            order: {
              items,
              total: totalCost,
              trackingNumber,
              find_ordernum,
              exchangeCost,
              subtotal,
              shippingPrice
            },
        })
        })
          .then(function (result) {
            return result.json();
          })
          .then(function (data) {
            const orderNum = data.orderNum
            if (data.success) {
              setLoading(false);
              confirmPayment();
            } else {
              setError({
                errorType: 'orderError',
                errorDetail: `Sorry! Something went wrong with your exchange order, please send us an email with your name and order number - ${orderNum}`
              });
              setLoading(false);
            }
          })
      }
    }
  }

  const exchangeSummary = items.map(function(e,i){
    return (
      <li key={i} className="exchangeSummaryItems">
        <p>{gownCap[e.from]}</p>
        <span className="changeLabel">change to</span>
        <p>{gownCap[e.to]}</p>
      </li>
    )
  });

  const CARD_OPTIONS = {
    hidePostalCode: true,
    classes: {
      base: 'stripe-base-style',
      focus: 'stripe-focus-style',
    },
  };

  return (
    <div className="exchangePayment">
      <h1>Step 5: Confirmation and Payment</h1>

      <div>
        <h3>EXCHANGE SUMMARY</h3>
        <ul>
          {exchangeSummary}
        </ul>
      </div>
      <br/>
      <section>
        <h3>DELIVERY DETAILS</h3>
        <div className="exchangePaymentDetails">
          <div>
            <p>{firstname} {lastname}</p>
            <p>{phone}</p>
            <p>{email}</p>
            <p>{address_line1}</p>
            { address_line2 &&
              <p>{address_line2}</p>
            }
            <p>{city}</p>
            <p>{state}</p>
            <p>{country}</p>
            <p>{postcode}</p>
          </div>
          <p id="confirmedShippingMethod">Shipping method: {shipping}</p>
        </div>
      </section>
      <section>
        <h3>PAYMENT SUMMARY</h3>
        <div className="exchangePaymentDetails">
          <p>Total Items Exchanged: {itemsExchanged}</p>
          <p>Cost per Exchange: ${exchangeCost}</p>
          <p>Total Exchange Cost: ${subtotal}</p>
          <p>Delivery Fee: ${shippingPrice}</p>
          <p>Total Exchange Fee: ${totalCost}</p>
        </div>
      </section>
      <section className="navigateButtonGroup">
      </section>
      <div className="stripe-section">
        <div>
      <section>
        <CardElement
          className="card"
          iconStyle="solid"
          hidePostalCode={true}
          options={CARD_OPTIONS}
        />
      </section>
      <section>
        <div className="stripe-icons">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Visa_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Mastercard_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/AMEX_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1551600751/Utility/powered_by_stripe.svg"/>
        </div>
        {loading
          ? <Loader />
          : (
            <button
              className="pay-button"
              disabled={loading || error}
              onClick={payWithCard}>
              <div className="button-content">
                <img src={LockSvg} />
                <span>{loading ? "Loading..." : "Pay with Stripe"}</span>
              </div>
            </button>
          )
        }
      </section>
      </div>
    </div>
      {error && <p className="error">{error.errorDetail}</p>}
      <NavigationButton
        handleClick={navigateBack}
        label="Back"
        disabled={loading}
      />
    </div>
  )
}


export default ExchangePayment;