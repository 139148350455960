import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { BARRISTER_GOWN_PRICE, ADMISSIONS_GOWN_PRICE, BARRISTER_WIG_PRICE, } from '../../constants';

const BuyLegalwear = () => {

  return (
    <div className="shop-products">
      <Helmet
        title="Wanting to Buy Quality Legal Wear? Fast NZ-Wide Delivery"
        meta={[
          {"name": "description", "content": "We are Tailors of Quality NZ Legal Wear at Competitive Prices! Fast Service and Easy Exchange."},
        ]}
      />
      <h1>Buy Handcrafted Legal Wear in New Zealand</h1>
      <div className="subheading">
        <h2>Quality legal gowns and wigs at affordable prices</h2>
      </div>
      <div className="catalogue">
        <div className="img-container">
          <div className="img-box">
            <Link to="/legal-wear/legal-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg" alt="Legal Admissions Gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Legal Gown</strong>
                  <p>${BARRISTER_GOWN_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/district-court-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg" alt="District Court Gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">District Court Gown</strong>
                  <p>${ADMISSIONS_GOWN_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/legal-wig">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png" alt="Legal Wig"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Legal Wig</strong>
                  <p>${BARRISTER_WIG_PRICE}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>Looking to Purchase Quality Legal Wear?</h2>
          <p>If you are a solicitor, barrister, or a recent law graduate, you've come to the right place. Gowning Street supplies premium quality legal wear to legal institutions across New Zealand. As a legal professional, we understand that it is important to look sharp at your proceedings. Our quality legal wear is perfect for New Zealand court rooms. You may order your legal wear from us online and delivery typically takes 1 to 2 business days for the North Island and two to three days South Island.
          </p>
          <h2>We have many Legal Regalia options available</h2>
          <p>Purchasing your legal regalia is simple with us. Just order online and we will dispatch it the same day if your order receipt is received by noon. We have an easy exchange and returns policy. If  the sizing is not right we can easily provide an exchange. You may wonder why our legal gowns are so competitively priced? As we are an online only multi-national business and our specialisation is in the manufacturing of gowns, we are able to cut out the middleman and provide you an exceptional product at an affordable price.</p>
          <h2>Need Custom Manufactured Legal Wear?</h2>
          <p>We are able to manufacture custom orders as well, just get in touch with us and we can go through the options.</p>
          <h2>Legal wigs</h2>
          <p>Our barrister’s wig is excellently crafted from 100% pure horsehair and is made for comfort.</p>
          <h2>Legal Gown</h2>
          <p>Whether you are getting a legal gown for admission or a gown for moving council. Our legal gowns are made to perfection from premium quality polyester. Our robes are suitable for use by Barrister’s in courtrooms around New Zealand.</p>
          <h2>District Court Gown</h2>
          <p>Our Uni-Sex Magistrate gowns are made of premium quality polyester weave. Our gowns are suitable for district courts around New Zealand. If you would like a custom designed gown, please get in touch with us as we can tailor a gown specific to your needs.</p>
        </div>
      </div>
    </div>
  )
};

export default BuyLegalwear;
