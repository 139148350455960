'use strict'

require('../scss/styles.scss');

import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Favicon from 'react-favicon'
import ReactGA from 'react-ga4';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Shopping from './components/shopping';
import Header from './components/header';
import Footer from './components/footer';
import About from './components/about';
import Buy from './components/buy';
import BuyFrames from './components/buy-frames';
import BuyGifts from './components/buy-gifts';
import BuyLegalwear from './components/buy-legalwear';
import Home from './components/homepage/home';
import Cart from './components/cart/cart';
import Checkout from './components/cart/checkout';
import PayPal from './components/paypalConfirmation';
import HoodOnly from './components/hoodonly';
import Thankyou from './components/thankyou';
import Friendsdeal from './components/friendsdeal';
import Contact from './components/info/contact';
import Regulations from './components/info/regulations';
import FAQ from './components/info/faq';
import Pricebeat from './components/info/pricebeat';
import Privacy from './components/info/privacy';
import Terms from './components/info/terms';
import ShippingInfo from './components/info/shipping';
import CheckOrder from './components/checkOrder';
import Admin from './components/admin/admin';
import Exchange from './components/exchange/exchangeForm';
import Wholesale from './components/info/wholesale';
import Product from './components/shopping-routes/product';
import Frame from './components/shopping-routes/frame';
import University from './components/shopping-routes/universities';
import NotFound from './components/404';
import Reducers from './reducers/index';


import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, Reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

const persistor = persistStore(store)

//Google Analytics
ReactGA.initialize('G-QYR4GZGNBC');

function logPageView() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "pageview" });
  window.scrollTo(0, 0);
  return null;
}


const mode = process.env.NODE_ENV;
let Stripe_Pk = loadStripe("pk_test_1orHprxP7G84f2EXlIP8OsX7");

if (mode === "production") {
  Stripe_Pk = loadStripe("pk_live_Sdkqg54Te3BoJArA6bIkI82O");
}

const Layout = props => (
  <>
    <Header />
    <Route path="/" component={logPageView}/>
    {props.children}
    <Footer />
  </>
);

export const App = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/regalia" component={Buy} />
    <Route exact path="/certificate-frame" component={BuyFrames} />
    <Route exact path="/graduation-gift" component={BuyGifts} />
    <Route exact path="/legal-wear" component={BuyLegalwear} />
    <Route path="/legal-wear/:uniset" component={Product} />
    <Route exact path="/regalia/graduation-hoods" component={HoodOnly} />
    <Route path="/regalia/:uniset" component={Product} />
    <Route path="/certificate-frame/:uni" component={Frame} />
    <Route path="/about" component={About} />
    <Route exact path="/cart" component={Cart} />
    <Route exact path="/checkout" component={Checkout} />
    <Route path="/paypalconf*" component={PayPal} />
    <Route path="/admin" component={Admin} />
    <Route path="/thankyou" component={Thankyou} />
    <Route path="/checkorder" component={CheckOrder} />
    <Route path="/save-with-friends" component={Friendsdeal} />
    <Route path="/wholesale" component={Wholesale}></Route>
    <Route path="/faq" component={FAQ} />
    <Route path="/contact" component={Contact} />
    <Route path="/regalia-regulations/:uni" component={Regulations} />
    <Route path="/pricebeat" component={Pricebeat} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/terms" component={Terms} />
    <Route path="/shipping" component={ShippingInfo} />
    <Route exact path="/exchange" component={Exchange} />
    <Route exact path="/waikato-uni" component={University} />
    <Route path="/waikato-uni/:uniset" component={Product} />
    <Route exact path="/aut" component={University} />
    <Route path="/aut/:uniset" component={Product} />
    <Route exact path="/auckland-uni" component={University} />
    <Route path="/auckland-uni/:uniset" component={Product} />
    <Route exact path="/victoria-university-of-wellington" component={University} />
    <Route path="/victoria-university-of-wellington/:uniset" component={Product} />
    <Route exact path="/canterbury-uni" component={University} />
    <Route path="/canterbury-uni/:uniset" component={Product} />
    <Route exact path="/massey-uni" component={University} />
    <Route path="/massey-uni/:uniset" component={Product} />
    <Route exact path="/lincoln-uni" component={University} />
    <Route path="/lincoln-uni/:uniset" component={Product} />
    <Route exact path="/university-of-otago" component={University} />
    <Route path="/university-of-otago/:uniset" component={Product} />
    <Route exact path="/wintec" component={University} />
    <Route path="/wintec/:uniset" component={Product} />
    <Route component={NotFound} />
  </Switch>
)

ReactDOM.hydrate(
  <Provider store={store}>
    <MuiThemeProvider>
      <Elements stripe={Stripe_Pk}>
          <Router>
              <Layout>
                <Favicon url='https://res.cloudinary.com/gowningstreet/image/upload/v1549165137/Utility/favicon.jpg' />
                <App />
              </Layout>
          </Router>
      </Elements>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('content')
);
