const prices = {
    BACHELOR_SET_PRICE: 109,
    BACHELOR_GOWN_CAP_PRICE: 99,
    BACHELOR_GOWN_PRICE: 89,
    MASTER_SET_PRICE: 109,
    MASTER_GOWN_CAP_PRICE: 99,
    MASTER_GOWN_PRICE: 89,
    PHD_SET_PRICE: 189,
    PHD_GOWN_PRICE: 169,
    HOOD_PRICE: 49,
    CAP_PRICE: 49,
    PHD_BONNET_PRICE: 69,
    // Gifts
    TEDDY_SMALL_PRICE: 19,
    PUPPY_SMALL_PRICE: 19,
    PUPPY_LARGE_PRICE: 25,
    // Frames
    MODERN_FRAME: 59,
    TRADITIONAL_FRAME: 64,
    //Shipping
    STANDARD_SHIPPING_PRICE: 11,
    STANDARD_SOUTH_SHIPPING_PRICE: 13,
    EXPRESS_SHIPPING_PRICE: 22,
    EXPRESS_SOUTH_SHIPPING_PRICE: 24,
    INTERNATIONAL_SHIPPING_PRICE: 70,
    // Legalwear
    BARRISTER_GOWN_PRICE: 204,
    ADMISSIONS_GOWN_PRICE: 199,
    BARRISTER_WIG_PRICE: 699,
    BENCHER_WIG_PRICE: 799,
    // Shipping

    // Misc.
    GARMENT_BAG: 14
}

module.exports = prices;