import React from 'react';
import Helmet from 'react-helmet';

const Privacy = () => (
  <div className="privacy-policy">
    <Helmet
      title="Privacy Policy"
      meta={[
        {"name": "description", "content": "Protecting your privacy is important to us. Please read for the full details"},
      ]}
    />
    <div className="privacy-contents">
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
        <p>By using the Gowning Street Website, you are consenting to the collection, use, and disclosure of information as set forth in our Privacy Policy. If you do not agree to be bound by our Privacy Policy, you may not access or use our Website</p>
      <h2>Collection and Use of Personal Information</h2>
        <p>Gowning Street collects your name, phone number, residential address and email address (“Personal Information”) and may use your Personal Information for the following purposes:</p>
        <p>1. To provide you information about a product or service; 2. To consider your request for a product or service; 3. To inform you of other products or services; 4. To perform administrative and operational tasks; 5. As may be required by relevant laws, regulations, codes, and external payment systems.</p>
        <p>If you do not provide some or all of the Personal Information requested, Gowning Street may be unable to provide you with a complete and accurate service.</p>
        <p>You are able to opt out of any direct marketing communication we may send you by following the opt-out instructions in that communication.</p>
        <p>Your profile and Personal Information will not be used for any purpose other than as reasonably necessary for the services provided by Gowning Street.</p>
        <p>Financial information (such as credit card number, expiration date) is passed on to a 3rd party provider, such as Paypal or Stripe. We use this information for billing purposes and to fill your orders.</p>
        <p>The security of your personal information is important to us. When you enter sensitive information (such as credit card numbers) on our website, we encrypt that information using secure socket layer technology (SSL). When Credit Card details are collected, we simply pass them on in order to be processed as required. We never permanently store complete Credit Card details.</p>
        <h2>Maintaining Personal Information</h2>
        <p>Personal Information stored on the Website, such as your email address, residential address, and phone number, may be requested by you at any time.</p>
        <p>Any changes to your Personal Information will be stored on our Website and your most recent changes will be considered your complete and correct contact information.</p>
        <p>To the extent that Gowning Street does share your personal information with a service provider, we would only do so if that party has agreed to comply with our privacy standards as described in this Privacy Policy. Some of our service providers may be overseas and may not be subject to New Zealand Privacy Laws.</p>
        <h2>Disclosure of Personal Information</h2>
        <p>Gowning Street may be required disclose certain information, which may include your Personal Information , to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request.</p>
        <p>If there is a change of control of Gowning Street (whether by merger, sale, or otherwise), or a sale or transfer of its assets, customer information, which may include your Personal Information, could be disclosed to a potential purchaser under an agreement to maintain confidentiality, or could be sold or transferred as part of that transaction.</p>
        <h2>Safeguarding Your Information</h2>
        <p>Gowning Street maintains reasonable administrative, physical and technological measures to protect the confidentiality and security of your information.</p>
        <p>Gowning street uses the Stripe and Paypal payment gateway for its online credit card transactions. Stripe and Paypal process online credit card transactions for thousands of New Zealand and Australian merchants, providing a safe and secure means of collecting payments via the internet All online credit card transactions performed on this site using the Stripe/Paypal gateway are secured payments. Payments are fully automated with an immediate response.Your complete credit card number cannot be viewed by Gowning Street or any outside party. All transactions are performed under 128 Bit SSL Certificate. All transaction data is encrypted for storage within Stripe/Paypal’s bank-grade data centre, further protecting your credit card data.Stripe/Paypal is an authorised third party processor for all the New Zealand and  Australian banks. Stripe and Paypal at no time touches your funds; all monies are directly transferred from your credit card to the merchant account held by Gowning Street.</p>
        <h2>General Use of Your Information</h2>
        <p>In general, Personal Information is used either to respond to requests that you make, or to aid Gowning Street in serving you. Gowning Street may use Personal Information in the following ways:</p>
        <p>1. To facilitate the creation of and secure your account on our network; 2. To provide improved administration of our Website and Services; 3. To provide the Services/Goods you may request; 4. To improve the quality of experience when you interact with our Website and Services; 5. To tailor the features, performance and support of the Website or Services; 6. To verify ownership of the e-mail address provided when your user account is created; 7. To send administrative e-mail notifications; 8. To respond to inquiries or requests and/or troubleshoot problems; 9. To make telephone calls to users</p>
        <h2>Cookies and Similar Technologies</h2>
        <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. When you access our Website we may send a “cookie” to your computer. This enables us to recognise your computer and greet you each time you visit our website, without bothering you with a request to register or log-in.  We may also use cookie technology to:</p>
        <p>1. Personalize our Website; 2. Collect information about the way users utilise the Website including what pages they visit and how long they stay on each page.; 3. To support the features and functionality of the Website including saving account settings established during previous visits.; 4. Keep track of products or services you view, so that Gowning Street can send you news and information about those products or services.; 5. Log IP addresses (the electronic addresses of computers connected to the internet) to analyse trends, administer the website, track user movements, and gather broad demographic information.; 6. Collect anonymous data (which is not personal information) relating to your activity on our Website (including IP addresses) or we may collect information from you in response to a survey.</p>
        <p>If you do not wish to receive cookies, you can set your browser so that your computer does not accept them. </p>
        <p>To the extent any information collected through the use of cookies does not constitute Personal Information because it does not identify you or anyone else, the New Zealand Privacy Principles do not apply and we may use this information for any purpose and by any means whatsoever.</p>
        <h2>Changes to Our Privacy Policy</h2>
        <p>As we plan to ensure our Privacy Policy remains current, this Privacy Policy is subject to change. We may modify this policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications to the Gowning Street Website. Please return periodically to review our Privacy Policy.</p>
        <h2>Links</h2>
        <p>Links on the Gowning Street site to external entities are not covered within this policy. The terms and conditions set out in this privacy statement only cover the domain name of www.graduationgowns.co.nz</p>
        <h2>Questions About Our Privacy Policy</h2>
        <p>If you have any questions regarding this Privacy Policy or our privacy practices, please contact Gowning Street.</p>
    </div>
  </div>
)

export default Privacy;