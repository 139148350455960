export const Frames = {
  // AUT
  "/certificate-frame/aut" : {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715047/Frames/AUT/AUT_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715048/Frames/AUT/AUT_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715049/Frames/AUT/AUT_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715049/Frames/AUT/AUT_Green.jpg" },
      { style: 'FRA00013', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728007/Frames/Waikato/Waikato_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715047/Frames/AUT/AUT_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715048/Frames/AUT/AUT_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715049/Frames/AUT/AUT_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715049/Frames/AUT/AUT_Green.jpg" },
      { style: 'FRA00003', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715046/Frames/AUT/AUT_Cream.jpg" },
    ],
    "metaTag" : "Buy your AUT certificate frame for only $49 | Fast delivery, excellent quality, made to fit your AUT testamur perfectly",
    "altTags" : [
      "AUT certificate frame - Pine Wood Manor Black",
      "AUT certificate frame - Pine Wood Marine Blue",
      "AUT certificate frame - Pine Wood Wine",
      "AUT certificate frame - Pine Wood Eucalypt",
      "AUT certificate frame - Pine Wood Light Cream",
      "AUT certificate frame - Modern Black",
      "AUT certificate frame - Modern Marine Blue",
      "AUT certificate frame - Modern Wine",
      "AUT certificate frame - Modern Eucalypt",
      "AUT certificate frame - Modern Light Cream",
    ],
    "name" : "AUT Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D1",
    "productType" : "bachelor-frame",
    "titleTag" : "AUT Certificate Frame | From $49"
  },

  "/certificate-frame/auckland-uni": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714383/Frames/Auckland/Auckland_Modern_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714386/Frames/Auckland/Auckland_Modern_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714386/Frames/Auckland/Auckland_Modern_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714385/Frames/Auckland/Auckland_Modern_Green.jpg" },
      { style: 'FRA00013', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714384/Frames/Auckland/Auckland_Modern_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714383/Frames/Auckland/Auckland_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714386/Frames/Auckland/Auckland_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714386/Frames/Auckland/Auckland_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714385/Frames/Auckland/Auckland_Modern_Green.jpg" },
      { style: 'FRA00003', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670714384/Frames/Auckland/Auckland_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Auckland University certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Auckland University testamur perfectly",
    "altTags" : [
      "Auckland University certificate frame - Pine Wood Manor Black",
      "Auckland University certificate frame - Pine Wood Marine Blue",
      "Auckland University certificate frame - Pine Wood Wine",
      "Auckland University certificate frame - Pine Wood Eucalypt",
      "Auckland University certificate frame - Pine Wood Light Cream",
      "Auckland University certificate frame - Modern Black",
      "Auckland University certificate frame - Modern Marine Blue",
      "Auckland University certificate frame - Modern Wine",
      "Auckland University certificate frame - Modern Eucalypt",
      "Auckland University certificate frame - Modern Light Cream",
    ],
    "name" : "Auckland University Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Auckland University Certificate Frame | From $49"
  },
 
  "/certificate-frame/waikato": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727974/Frames/Waikato/Waikato_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566729477/Frames/Waikato/Waikato_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728813/Frames/Waikato/Waikato_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728026/Frames/Waikato/Waikato_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728007/Frames/Waikato/Waikato_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284574/Frames/Waikato/Waikato_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273311/Frames/Waikato/Waikato_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282799/Frames/Waikato/Waikato_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281726/Frames/Waikato/Waikato_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Waikato University certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Waikato Uni testamur perfectly",
    "altTags" : [
      "Waikato University certificate frame - Pine Wood Manor Black",
      "Waikato University certificate frame - Pine Wood Marine Blue",
      "Waikato University certificate frame - Pine Wood Wine",
      "Waikato University certificate frame - Pine Wood Eucalypt",
      "Waikato University certificate frame - Pine Wood Light Cream",
      "Waikato University certificate frame - Modern Black",
      "Waikato University certificate frame - Modern Marine Blue",
      "Waikato University certificate frame - Modern Wine",
      "Waikato University certificate frame - Modern Eucalypt",
      "Waikato University certificate frame - Modern Light Cream",
    ],
    "name" : "Waikato University Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Waikato University Certificate Frame | From $49"
  },
 
  "/certificate-frame/lincoln-uni": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727974/Frames/Waikato/Waikato_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566729477/Frames/Waikato/Waikato_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728813/Frames/Waikato/Waikato_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728026/Frames/Waikato/Waikato_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728007/Frames/Waikato/Waikato_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284574/Frames/Waikato/Waikato_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273311/Frames/Waikato/Waikato_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282799/Frames/Waikato/Waikato_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281726/Frames/Waikato/Waikato_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Lincoln University certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Lincoln Uni testamur perfectly",
    "altTags" : [
      "Lincoln University certificate frame - Pine Wood Manor Black",
      "Lincoln University certificate frame - Pine Wood Marine Blue",
      "Lincoln University certificate frame - Pine Wood Wine",
      "Lincoln University certificate frame - Pine Wood Eucalypt",
      "Lincoln University certificate frame - Pine Wood Light Cream",
      "Lincoln University certificate frame - Modern Black",
      "Lincoln University certificate frame - Modern Marine Blue",
      "Lincoln University certificate frame - Modern Wine",
      "Lincoln University certificate frame - Modern Eucalypt",
      "Lincoln University certificate frame - Modern Light Cream",
    ],
    "name" : "Lincoln University Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Lincoln University Certificate Frame | From $49"
  },
 
  "/certificate-frame/massey-uni": {
    "photos" : [
       { style: 'FRA00012', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727974/Frames/Waikato/Waikato_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566729477/Frames/Waikato/Waikato_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728813/Frames/Waikato/Waikato_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728026/Frames/Waikato/Waikato_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728007/Frames/Waikato/Waikato_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284574/Frames/Waikato/Waikato_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273311/Frames/Waikato/Waikato_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282799/Frames/Waikato/Waikato_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281726/Frames/Waikato/Waikato_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Massey University certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Massey Uni testamur perfectly",
    "altTags" : [
      "Massey University certificate frame - Pine Wood Manor Black",
      "Massey University certificate frame - Pine Wood Marine Blue",
      "Massey University certificate frame - Pine Wood Wine",
      "Massey University certificate frame - Pine Wood Eucalypt",
      "Massey University certificate frame - Pine Wood Light Cream",
      "Massey University certificate frame - Modern Black",
      "Massey University certificate frame - Modern Marine Blue",
      "Massey University certificate frame - Modern Wine",
      "Massey University certificate frame - Modern Eucalypt",
      "Massey University certificate frame - Modern Light Cream",
    ],
    "name" : "Massey University Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Massey University Certificate Frame | From $49"
  },

  "/certificate-frame/canterbury-uni": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727974/Frames/Waikato/Waikato_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566729477/Frames/Waikato/Waikato_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728813/Frames/Waikato/Waikato_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728026/Frames/Waikato/Waikato_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728007/Frames/Waikato/Waikato_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284574/Frames/Waikato/Waikato_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273311/Frames/Waikato/Waikato_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282799/Frames/Waikato/Waikato_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281726/Frames/Waikato/Waikato_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Canterbury certificate frame for only $49 | Fast delivery, excellent quality, made to fit your University of Canterbury testamur perfectly",
    "altTags" : [
      "University of Canterbury certificate frame - Pine Wood Manor Black",
      "University of Canterbury certificate frame - Pine Wood Marine Blue",
      "University of Canterbury certificate frame - Pine Wood Wine",
      "University of Canterbury certificate frame - Pine Wood Eucalypt",
      "University of Canterbury certificate frame - Pine Wood Light Cream",
      "University of Canterbury certificate frame - Modern Black",
      "University of Canterbury certificate frame - Modern Marine Blue",
      "University of Canterbury certificate frame - Modern Wine",
      "University of Canterbury certificate frame - Modern Eucalypt",
      "University of Canterbury certificate frame - Modern Light Cream",
    ],
    "name" : "University of Canterbury Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Canterbury Certificate Frame | From $49"
  },

  "/certificate-frame/university-of-otago": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727974/Frames/Waikato/Waikato_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566729477/Frames/Waikato/Waikato_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728813/Frames/Waikato/Waikato_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728026/Frames/Waikato/Waikato_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728007/Frames/Waikato/Waikato_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284574/Frames/Waikato/Waikato_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273311/Frames/Waikato/Waikato_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282799/Frames/Waikato/Waikato_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281726/Frames/Waikato/Waikato_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Otago certificate frame for only $49 | Fast delivery, excellent quality, made to fit your University of Otago testamur perfectly",
    "altTags" : [
      "University of Otago certificate frame - Pine Wood Manor Black",
      "University of Otago certificate frame - Pine Wood Marine Blue",
      "University of Otago certificate frame - Pine Wood Wine",
      "University of Otago certificate frame - Pine Wood Eucalypt",
      "University of Otago certificate frame - Pine Wood Light Cream",
      "University of Otago certificate frame - Modern Black",
      "University of Otago certificate frame - Modern Marine Blue",
      "University of Otago certificate frame - Modern Wine",
      "University of Otago certificate frame - Modern Eucalypt",
      "University of Otago certificate frame - Modern Light Cream",
    ],
    "name" : "University of Otago Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Otago Certificate Frame | From $49"
  },

  "/certificate-frame/victoria-university-of-wellington": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727460/Frames/VICU_Wellington/VICU_Wellington_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727656/Frames/VICU_Wellington/VICU_Wellington_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727527/Frames/VICU_Wellington/VICU_Wellington_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727504/Frames/VICU_Wellington/VICU_Wellington_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727480/Frames/VICU_Wellington/VICU_Wellington_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284590/Frames/VICU_Wellington/VICU_Wellington_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273327/Frames/VICU_Wellington/VICU_Wellington_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283338/Frames/VICU_Wellington/VICU_Wellington_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282820/Frames/VICU_Wellington/VICU_Wellington_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281741/Frames/VICU_Wellington/VICU_Wellington_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Victoria University of Wellington certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Victoria University of Wellington testamur perfectly",
    "altTags" : [
      "Victoria University of Wellington certificate frame - Pine Wood Manor Black",
      "Victoria University of Wellington certificate frame - Pine Wood Marine Blue",
      "Victoria University of Wellington certificate frame - Pine Wood Wine",
      "Victoria University of Wellington certificate frame - Pine Wood Eucalypt",
      "Victoria University of Wellington certificate frame - Pine Wood Light Cream",
      "Victoria University of Wellington certificate frame - Modern Black",
      "Victoria University of Wellington certificate frame - Modern Marine Blue",
      "Victoria University of Wellington certificate frame - Modern Wine",
      "Victoria University of Wellington certificate frame - Modern Eucalypt",
      "Victoria University of Wellington certificate frame - Modern Light Cream",
    ],
    "name" : "Victoria University of Wellington Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Victoria University of Wellington Certificate Frame | From $49"
  },
  
  "/certificate-frame/wintec": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715591/Frames/Wintec/Wintec_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715594/Frames/Wintec/Wintec_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715592/Frames/Wintec/Wintec_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715592/Frames/Wintec/Wintec_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715594/Frames/Wintec/Wintec_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715591/Frames/Wintec/Wintec_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715594/Frames/Wintec/Wintec_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715592/Frames/Wintec/Wintec_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715592/Frames/Wintec/Wintec_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715594/Frames/Wintec/Wintec_Cream.jpg" },
    ],
    "metaTag" : "Buy your Wintec certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Wintec testamur perfectly",
    "altTags" : [
      "Wintec certificate frame - Pine Wood Manor Black",
      "Wintec certificate frame - Pine Wood Marine Blue",
      "Wintec certificate frame - Pine Wood Wine",
      "Wintec certificate frame - Pine Wood Eucalypt",
      "Wintec certificate frame - Pine Wood Light Cream",
      "Wintec certificate frame - Modern Black",
      "Wintec certificate frame - Modern Marine Blue",
      "Wintec certificate frame - Modern Wine",
      "Wintec certificate frame - Modern Eucalypt",
      "Wintec certificate frame - Modern Light Cream",
    ],
    "name" : "Wintec Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Wintec Certificate Frame | From $49"
  },

  "/certificate-frame/toi-ohomai-tech": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715374/Frames/ToiOhomai/Toi_Ohomai_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715375/Frames/ToiOhomai/Toi_Ohomai_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715373/Frames/ToiOhomai/Toi_Ohomai_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715374/Frames/ToiOhomai/Toi_Ohomai_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715372/Frames/ToiOhomai/Toi_Ohomai_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715374/Frames/ToiOhomai/Toi_Ohomai_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715375/Frames/ToiOhomai/Toi_Ohomai_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715373/Frames/ToiOhomai/Toi_Ohomai_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715374/Frames/ToiOhomai/Toi_Ohomai_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715372/Frames/ToiOhomai/Toi_Ohomai_Cream.jpg" },
    ],
    "metaTag" : "Buy your Toi Ohomai certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Toi Ohomai testamur perfectly",
    "altTags" : [
      "Toi Ohomai certificate frame - Pine Wood Manor Black",
      "Toi Ohomai certificate frame - Pine Wood Marine Blue",
      "Toi Ohomai certificate frame - Pine Wood Wine",
      "Toi Ohomai certificate frame - Pine Wood Eucalypt",
      "Toi Ohomai certificate frame - Pine Wood Light Cream",
      "Toi Ohomai certificate frame - Modern Black",
      "Toi Ohomai certificate frame - Modern Marine Blue",
      "Toi Ohomai certificate frame - Modern Wine",
      "Toi Ohomai certificate frame - Modern Eucalypt",
      "Toi Ohomai certificate frame - Modern Light Cream",
    ],
    "name" : "Toi Ohomai Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Toi Ohomai Certificate Frame | From $49"
  },

  "/certificate-frame/open-polytechnic": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727460/Frames/VICU_Wellington/VICU_Wellington_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727656/Frames/VICU_Wellington/VICU_Wellington_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727527/Frames/VICU_Wellington/VICU_Wellington_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727504/Frames/VICU_Wellington/VICU_Wellington_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727480/Frames/VICU_Wellington/VICU_Wellington_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284590/Frames/VICU_Wellington/VICU_Wellington_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273327/Frames/VICU_Wellington/VICU_Wellington_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283338/Frames/VICU_Wellington/VICU_Wellington_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282820/Frames/VICU_Wellington/VICU_Wellington_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281741/Frames/VICU_Wellington/VICU_Wellington_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Open Polytechnic certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Open Polytechnic testamur perfectly",
    "altTags" : [
      "Open Polytechnic certificate frame - Pine Wood Manor Black",
      "Open Polytechnic certificate frame - Pine Wood Marine Blue",
      "Open Polytechnic certificate frame - Pine Wood Wine",
      "Open Polytechnic certificate frame - Pine Wood Eucalypt",
      "Open Polytechnic certificate frame - Pine Wood Light Cream",
      "Open Polytechnic certificate frame - Modern Black",
      "Open Polytechnic certificate frame - Modern Marine Blue",
      "Open Polytechnic certificate frame - Modern Wine",
      "Open Polytechnic certificate frame - Modern Eucalypt",
      "Open Polytechnic certificate frame - Modern Light Cream",
    ],
    "name" : "Open Polytechnic Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Open Polytechnic Certificate Frame | From $49"
  },

  "/certificate-frame/whitireia-weltec": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715450/Frames/Weltech/Weltech_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715450/Frames/Weltech/Weltech_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715451/Frames/Weltech/Weltech_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715449/Frames/Weltech/Weltech_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715450/Frames/Weltech/Weltech_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715450/Frames/Weltech/Weltech_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715450/Frames/Weltech/Weltech_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715451/Frames/Weltech/Weltech_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715449/Frames/Weltech/Weltech_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715450/Frames/Weltech/Weltech_Cream.jpg" },
    ],
    "metaTag" : "Buy your Whitireia and WelTec certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Whitireia and WelTec testamur perfectly",
    "altTags" : [
      "Whitireia and WelTec certificate frame - Pine Wood Manor Black",
      "Whitireia and WelTec certificate frame - Pine Wood Marine Blue",
      "Whitireia and WelTec certificate frame - Pine Wood Wine",
      "Whitireia and WelTec certificate frame - Pine Wood Eucalypt",
      "Whitireia and WelTec certificate frame - Pine Wood Light Cream",
      "Whitireia and WelTec certificate frame - Modern Black",
      "Whitireia and WelTec certificate frame - Modern Marine Blue",
      "Whitireia and WelTec certificate frame - Modern Wine",
      "Whitireia and WelTec certificate frame - Modern Eucalypt",
      "Whitireia and WelTec certificate frame - Modern Light Cream",
    ],
    "name" : "Whitireia and WelTec Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Whitireia and WelTec Certificate Frame | From $49"
  },

  "/certificate-frame/nmit": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715265/Frames/NMIT/NMIT_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715264/Frames/NMIT/NMIT_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715263/Frames/NMIT/NMIT_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715265/Frames/NMIT/NMIT_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715267/Frames/NMIT/NMIT_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715265/Frames/NMIT/NMIT_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715264/Frames/NMIT/NMIT_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715263/Frames/NMIT/NMIT_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715265/Frames/NMIT/NMIT_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715267/Frames/NMIT/NMIT_Cream.jpg" },
    ],
    "metaTag" : "Buy your Nelson Marlborough Institute of Technology certificate frame for only $49 | Fast delivery, excellent quality, made to fit your NMIT testamur perfectly",
    "altTags" : [
      "NMIT certificate frame - Pine Wood Manor Black",
      "NMIT certificate frame - Pine Wood Marine Blue",
      "NMIT certificate frame - Pine Wood Wine",
      "NMIT certificate frame - Pine Wood Eucalypt",
      "NMIT certificate frame - Pine Wood Light Cream",
      "NMIT certificate frame - Modern Black",
      "NMIT certificate frame - Modern Marine Blue",
      "NMIT certificate frame - Modern Wine",
      "NMIT certificate frame - Modern Eucalypt",
      "NMIT certificate frame - Modern Light Cream",
    ],
    "name" : "NMIT Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "NMIT Certificate Frame | From $49"
  },

  "/certificate-frame/mit-manukau": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715157/Frames/MIT/Manukau_MIT_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715159/Frames/MIT/Manukau_MIT_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715157/Frames/MIT/Manukau_MIT_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715157/Frames/MIT/Manukau_MIT_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715158/Frames/MIT/Manukau_MIT_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715157/Frames/MIT/Manukau_MIT_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715159/Frames/MIT/Manukau_MIT_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715157/Frames/MIT/Manukau_MIT_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715157/Frames/MIT/Manukau_MIT_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1670715158/Frames/MIT/Manukau_MIT_Cream.jpg" },
    ],
    "metaTag" : "Buy your Manukau Institute of Technology certificate frame for only $49 | Fast delivery, excellent quality, made to fit your MIT Manukau testamur perfectly",
    "altTags" : [
      "MIT Manukau certificate frame - Pine Wood Manor Black",
      "MIT Manukau certificate frame - Pine Wood Marine Blue",
      "MIT Manukau certificate frame - Pine Wood Wine",
      "MIT Manukau certificate frame - Pine Wood Eucalypt",
      "MIT Manukau certificate frame - Pine Wood Light Cream",
      "MIT Manukau certificate frame - Modern Black",
      "MIT Manukau certificate frame - Modern Marine Blue",
      "MIT Manukau certificate frame - Modern Wine",
      "MIT Manukau certificate frame - Modern Eucalypt",
      "MIT Manukau certificate frame - Modern Light Cream",
    ],
    "name" : "MIT Manukau Certificate Frame",
    "price" : 49,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "MIT Manukau Certificate Frame | From $49"
  },

}