import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import checkInventory from './checkInventory';
import { gownCap } from '../../sources/gowncap';
import Loader from '../custom-items/loader';
import StripePayment from './stripepayment';
import ShippingCalc from '../../../functions/shippingcalc';
import PriceCalc from '../../../functions/pricecalc';

const Confirmation = (props) => {
  const {
    order,
    customer,
    customer: {
      firstname,
      lastname,
      phone,
      address_line1,
      address_line2,
      city,
      state,
      postcode,
      country,
    },
    handleSubmit
  } = props;

  const shippingDetails = ShippingCalc(order, customer);
  const priceDetails = PriceCalc(order, shippingDetails);
  const total = priceDetails.total;
  const subtotal = priceDetails.subtotal;
  const shippingCost = shippingDetails.total;
  const discount = priceDetails.discount;
  const eligibleItems = priceDetails.eligibleItems;
  const discountFactor = priceDetails.discountFactor;

  const DeliveryTypes = {
    "standard": "Standard: Permission to leave on premises",
    "express": "Express: Permission to leave on premises",
    "international": "International"
  }

  let selectedShipping = DeliveryTypes[customer.shipping];

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const paypalCheckout = () => {
    if(loading === false){
      setLoading(true)

      checkInventory(order, function(fails){
        if(fails.length > 0){
          setError(fails);
          setLoading(false);
        }
        else {
          const token = Math.floor(Math.random() * 99999999999999999999);

          axios.post('/api/paypal', {
            total: total,
            order: order,
            customer: customer,
            token: token
          })
          .then(response => {
            if (response.data.success === true) {

              window.location = response.data.body
            } else {
              setError({
                errorType: 'paypal',
                errorDetail: 'Sorry, we are unable to connect to paypal for now. Please choose another payment option.'
              });
            }
          })
          .catch(error => {
            setError({
              errorType: 'paypal',
              errorDetail: 'Sorry, we are having trouble connecting to paypal. Please choose another payment option.'
            });
          });
        }
      });
    }
  }

  const goBack = () => {
    handleSubmit("shipping");
  }

  const renderOrderDetails = order.map(function(item){
    return(
      <tr className="orderitem" key={item.id}>
        <td className="ordercontent">
          <p className="name">{item.name}</p>
          <div className="details">
          { gownCap[item.gown] && <p className="details">{gownCap[item.gown]}</p> }
          { gownCap[item.cap] &&  <p className="details">{gownCap[item.cap]}</p> }
          <p className="details">{item.hoodStyle !== null ? "Hood - ".concat(item.hoodStyle) : null}</p>
          </div>
        </td>
        <td className="price">${item.price}</td>
        <td className="qty">{item.qty}</td>
      </tr>
    )
  });

  const OrderSummary = () => (
    <div className="confirmation-section">
      <div className="section-container">
        <div className="confirmation-section-header">
          <h3>Order Summary</h3>
          <div>
            <Link to="/cart">Edit Cart</Link>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Price</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {renderOrderDetails}
          </tbody>
        </table>
      </div>
      <div className="section-container">
        <div className="border-bottom-group">
          <div className="price-display">
            <span>Delivery: </span>
            <span>${shippingCost}</span>
          </div>
        <div className="price-display">
           <span>Discount per Item: </span>
          <span>{discountFactor}</span>
        </div>
        <div className="price-display">
          <span>Eligible Items: </span>
          <span>{eligibleItems}</span>
        </div>
          <div className="price-display">
            <span>Total Discount: </span>
            <span>${discount}</span>
          </div>
          <div className="price-display">
            <span>Subtotal: </span>
            <span>${subtotal}</span>
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="border-bottom-group">
          <div className="price-display">
            <span>Order Total: </span>
            <span>${total}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderShippingDetails = (
    <div className="shippingdetails">
      <p>{firstname} {lastname}</p>
      <p>{address_line1},</p>
      <p>{address_line2 && `${address_line2}, `}</p>
      <p>{city + ", " + postcode}</p>
      <p>{state && state + ','} {country}</p>
      <p>Contact Number: {phone}</p>
      <br></br>
      <p>Shipping Method: {selectedShipping}</p>
    </div>
  );

  const DeliveryDetails = () => (
    <div className="confirmation-section">
      <div className="section-container">
        <div className="confirmation-section-header">
          <h3>Delivery Details</h3>
          <div>
            <p className="link" onClick={goBack}>
              Edit Shipping Details
            </p>
          </div>
        </div>
      </div>
      <div className="section-container">
        {renderShippingDetails}
      </div>
    </div>
  );

  const PaymentDetails = () => (
    <div className="confirmation-section">
      <div className="section-container">
        <div className="confirmation-section-header">
          <h3>Payment methods</h3>
        </div>
      </div>
      <div className="payment-section">
        <div className="section-container">
          <div className="confirmation-section-header">
            <h5>Card Payment</h5>
          </div>
            <StripePayment
              total={total}
              customer={customer}
              setError={setError}
            />
            { error &&
              <div className="errors">  
                <ErrorMessage error={error} />
              </div>
            }
        </div>
        <div className="section-container">
          <div className="confirmation-section-header">
            <h5>Paypal</h5>
          </div>
          <div className="paypal-section">
            {loading
              ? <Loader />
              : (<img className="PayPalButton" onClick={paypalCheckout} src="https://www.paypal.com/en_US/i/btn/btn_xpressCheckout.gif" />)
            }

          </div>
          <p className="payment-footer">All transactions are secure and encrypted</p>
        </div>
      </div>
    </div>
  );

  const ErrorMessage = ({ error: { errorType, errorDetail } }) => {
    let message = <p>Something went wrong, please try again later.</p>
    if (errorType === 'stripe') {
      message = (
        <div>
          { errorDetail && <p>{errorDetail}</p> }
          <p>
            Please try another card or use an alternative payment method.
          </p>
        </div>
      )
    }
    if (errorType === 'inventory') {
      message = errorDetail.map((detail) => (
          <div key={detail.code}>
            <p>Sorry, we only have {detail.maxQty} of {detail.description}.</p>
            <p>Please choose a lower amount.</p>
            <p>Please click <Link to="/cart">here</Link> to edit cart</p>
          </div>
        )
      );
    }
    if (errorType === 'orderError' || errorType === 'paypal') {
    message = (<p>{errorDetail}</p>)
    }
    return message;
  };

  return (
    <div className="checkout-confirmation">
      <OrderSummary />
      <DeliveryDetails />
      <PaymentDetails />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);




